import React, { memo, useMemo } from 'react'
import { getRandomLockedChannelPoster } from '~/utils/helpers'
import Image from '../../Image'

import '../styles.scss'

interface CategoryCardProps {
  title: string | JSX.Element
  imageUrl: string | null
  defaultUrl?: string | null
  showOnHoverTitle?: string
  handleClick: () => void
  className?: string
  locked?: boolean
}

export const CategoryCardComponent = (
  props: CategoryCardProps
): JSX.Element => {
  const {
    title,
    showOnHoverTitle,
    imageUrl,
    handleClick,
    className,
    locked,
    defaultUrl,
  } = props
  const image = useMemo(() => getRandomLockedChannelPoster(), [imageUrl])
  return (
    <div className={`card ${className && className}`} onClick={handleClick}>
      {locked ? (
        <img src={image} />
      ) : (
        <Image
          imageUrl={imageUrl}
          defaultUrl={defaultUrl ? defaultUrl : '/img/noLogo.svg'}
        />
      )}
      <div className='category-card__title'>{title}</div>
      {showOnHoverTitle !== '' ? (
        <div className='show-on-hover'>{showOnHoverTitle}</div>
      ) : null}
    </div>
  )
}

export const CategoryCard = memo(CategoryCardComponent)
