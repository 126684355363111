import React, { useEffect, useCallback } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import * as tvShowActionCreators from '../../tvShows/tvShowsActions'
import { TvShowCategory } from '../interfaces'
import SearchField from '/components/common/SearchField'
import { HorizontalCard } from '/components/common/cards/horizontal-card/HorizontalCard'
import { CategoryCard } from '/components/common/cards/category-card/CategoryCard'
import { TvShowsCarouselContainer } from '../components/TvShowsCarousel'
import { Carousel } from '~/components/common/carousel/Carousel'
import { CardTypes, getRandomPoster } from '~/utils/helpers'
import { Movie } from '/components/vod/interfaces'
import { Grid } from '/components/common/grid-cards-layout'
import { NoContent } from '/components/common/no-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faVideoSlash } from '@fortawesome/pro-regular-svg-icons'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  categoryId: string
  subCategoryId?: string
  location: Location
}

function TvShowsHome(props: Props) {
  const {
    categories,
    content,
    search,
    searchQuery,
    loadContent,
    getTvShowsBySearchRequest,
    changeTvShowsSearchQuery,
    carousel,
  } = props
  const maxLength = 36
  const { t } = useTranslation()

  useEffect(() => {
    if (categories.length) {
      categories.forEach((category: TvShowCategory) => {
        const isCashed = Boolean(content[category.id])
        isCashed || loadContent({ id: category.id, page: 0, count: maxLength })
      })
    }
  }, [categories])

  const handleSearch = (searchQuery) => {
    if (searchQuery)
      getTvShowsBySearchRequest({
        searchQuery,
        categoryId: 0,
      })
    return changeTvShowsSearchQuery(searchQuery)
  }

  const handleMovieClick = useCallback(
    (id: number, categoryId = 0) => {
      return navigate(`/shows/categories/${categoryId}/${id}`)
    },
    [content]
  )

  const handleCategoryClick = useCallback(
    (id: number, subCategoryId?: number) => () => {
      let url = `/shows/categories/${id}`
      if (subCategoryId) url = `/shows/categories/${id}/subs/${subCategoryId}`

      return navigate(url)
    },
    [categories]
  )

  return (
    <>
      <div className='page-search-breadcrumbs-wrapper'>
        {searchQuery && (
          <div className='breadcrumbs'>
            <span>{t('TV Series')}</span>
          </div>
        )}
        <SearchField
          placeholder={t('tvshow:Search TV Series')}
          handleSearch={handleSearch}
        />
      </div>

      {searchQuery ? (
        search.length ? (
          <Grid
            id={0}
            page={{ number: 0, last: true }}
            getItemsByPage={() => null}
            className={'base-cards'}
            title={t('Search Result ({{length}})', { length: search.length })}
          >
            {search?.map((item) => {
              return (
                <HorizontalCard
                  key={`${item.id}${item.name}`}
                  title={item.name}
                  imageUrl={item.landscapeImageUrl}
                  handleClick={() => handleMovieClick(item.id, 0)}
                />
              )
            })}
          </Grid>
        ) : (
          <NoContent
            title={t('No search results found')}
            icon={<FontAwesomeIcon icon={faSearch} className='icon' />}
          />
        )
      ) : (!carousel?.enabledOnModulePage || !carousel?.content?.length) &&
        !categories.filter(
          (category: TvShowCategory) =>
            content &&
            content[category.id] &&
            content[category.id].movies?.length
        ).length ? (
        <NoContent
          icon={<FontAwesomeIcon icon={faVideoSlash} className='icon' />}
          title={t('No content')}
        />
      ) : (
        <div className='page__content'>
          {carousel?.enabledOnModulePage && (
            <TvShowsCarouselContainer
              id={'showFeatured'}
              cardType={CardTypes.featuredCard}
            />
          )}
          {categories.map((category: TvShowCategory) => {
            const list = (category.subCategories || []).concat(
              content?.[category.id]?.movies || []
            )

            return list.length ? (
              <Carousel
                id={`show_${category.id}`}
                categoryId={category.id}
                key={category.id}
                carouselTitle={
                  category.name === 'All' ? t('All') : category.name
                }
                showAllOption={
                  content[category.id]?.page?.totalElements > maxLength ||
                  list.length > maxLength
                }
                handleCategoryClick={handleCategoryClick(category.id)}
              >
                {list.slice(0, maxLength).map((item: any) => {
                  const isCategory = item.subCategories
                  const imageUrl = isCategory
                    ? getRandomPoster()
                    : item.landscapeImageUrl

                  return isCategory ? (
                    <CategoryCard
                      key={`${item.id}${item.name}`}
                      title={item.name}
                      imageUrl={imageUrl}
                      showOnHoverTitle={``}
                      handleClick={handleCategoryClick(category.id, item.id)}
                      className={'category-card '}
                    />
                  ) : (
                    <HorizontalCard
                      key={`${item.id}${item.name}`}
                      title={item.name}
                      imageUrl={imageUrl}
                      handleClick={() => handleMovieClick(item.id, category.id)}
                    />
                  )
                })}
              </Carousel>
            ) : null
          })}
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    categories: state.views.tvShows.categories,
    content: state.views.tvShows.content,
    search: state.views.tvShows.content.search,
    activeCategory: state.views.tvShows.activeCategory,
    searchQuery: state.views.tvShows.searchQuery,
    carousel: state.views.tv.carousels?.find((item) => item.type === 'TV_SHOW'),
  }
}

const mapDispatchToProps = {
  loadContent: tvShowActionCreators.getTvShowsByCategory,
  getActiveCategoryRequest: tvShowActionCreators.getActiveCategoryRequest,
  getTvShowsByCategoryByPage: tvShowActionCreators.getTvShowsByCategoryByPage,
  getTvShowsBySearchRequest: tvShowActionCreators.getTvShowsBySearchRequest,
  changeTvShowsSearchQuery: tvShowActionCreators.changeTvShowsSearchQuery,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const TvShowsHomePage = connector(TvShowsHome)
