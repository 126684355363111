import React, { ReactNode } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

import './styles.scss'

interface IButtonProps {
  icon?: ReactNode
  active?: boolean
  isDisabled?: boolean
  ticked?: boolean
  handleClick: () => void
  className?: string
  title?: string | JSX.Element
}

export const Button = ({
  className,
  active,
  ticked,
  handleClick,
  title,
  icon,
  isDisabled,
}: IButtonProps): JSX.Element => {
  return (
    <button
      className={cn(className, {
        'button--active': active,
      })}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {typeof title === 'string' ? (
        <>
          <span className='button__title'>{title}</span>
          {icon}
        </>
      ) : (
        title
      )}
      {ticked && <FontAwesomeIcon icon={faCheck} />}
    </button>
  )
}
