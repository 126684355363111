import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../common/carousel/Carousel'
import { CategoryCard } from '../common/cards/category-card/CategoryCard'
import { getChannelLogo, getChannelLogoSmall } from './helpers'
import { CardTypes, getRandomLockedChannelPoster } from '/utils/helpers'
import { useNavigate } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-solid-svg-icons'

const TvCarouselContainer = ({
  id,
  carousel,
  cardType,
  handleMovieClick = null,
}) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation('home')

  const handleClick = (channel) => {
    handleMovieClick && handleMovieClick(channel)
    navigate(`/tv/categories/0/${channel.id}`)
  }

  return carousel?.content?.length ? (
    <Carousel
      id={id}
      categoryId={'featuredChannels'}
      key={"'featuredChannels'"}
      carouselTitle={t('Featured Live Channels')}
      className='featured'
      showAllOption={false}
    >
      {carousel &&
        carousel.content
          ?.slice(0, maxLength)
          .map(({ channel }) => (
            <CategoryCard
              key={`${channel.id}/${channel.name}`}
              title={
                channel.locked && (
                  <FontAwesomeIcon icon={faLock} className='locked-icon' />
                )
              }
              className={
                cardType === CardTypes.tvChannelCard
                  ? 'tv-channel-card'
                  : 'tv-channel-card_featured'
              }
              showOnHoverTitle={`${channel.channelNumber}. ${channel.name}`}
              imageUrl={getChannelLogo(channel.logoUrl)}
              handleClick={() => handleClick(channel)}
              locked={channel.locked}
              defaultUrl={getChannelLogoSmall(channel.logoUrl)}
            />
          ))}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    carousel: state.views.tv.carousels?.find(
      (item) => item.type === 'TV_CHANNEL'
    ),
  }
}

const mapDispatchToProps = {}

export const TvCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(TvCarouselContainer)
