import React, { useEffect, useState } from 'react'

const Image = ({
  imageUrl,
  defaultUrl,
  className,
}: {
  imageUrl: string | null
  defaultUrl?: string | null
  className?: string
}) => {
  const [error, setError] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)

  useEffect(() => {
    if (imageUrl) {
      const img = new window.Image()
      img.onerror = () => setError(true)

      img.onload = () => setLoad(true)
      img.src = imageUrl
    }
  }, [imageUrl])

  if (load && !error && imageUrl) {
    return <img src={imageUrl} className={className ?? ''} />
  } else if (!load && error && defaultUrl) {
    return (
      <div className='no-content-image '>
        <img src={defaultUrl} />
      </div>
    )
  } else if (!load && !error && defaultUrl) {
    return (
      <div className='no-content-image '>
        <img src={defaultUrl} />
      </div>
    )
  } else if (load && error && defaultUrl) {
    return (
      <div className='no-content-image '>
        <img src={defaultUrl} />
      </div>
    )
  }
  return (
    <div className={'no-content-image'}>
      {defaultUrl !== undefined && <img src={'/img/noLogo.svg'} />}
    </div>
  )
}

export default Image
