import { createAsyncThunk } from '@reduxjs/toolkit'
import * as service from '/services/api/catchups'
import { fetchCatchupByChannel } from '/services/api/v3/catchups'
import {
  GET_CATCHUPS_LIST_SUCCESS,
  GET_CATCHUP_SUCCESS,
} from '../../../root/actionTypes'
import { CatchupChannel } from '../interfaces/index'
import { mapCatchupsToDays } from '../../../utils/catchups'
import { actions as catchupActions } from '~/components/catchup/store'
import { formatDateForCatchupDays, formatDateForEPG } from '~/utils/date'

export const changeCatchupChannel = createAsyncThunk(
  GET_CATCHUP_SUCCESS,
  async (channel: CatchupChannel, { rejectWithValue, dispatch }) => {
    try {
      const response = await service.fetchCatchupByChannel(channel.id)
      dispatch(catchupActions.setActiveChannel(channel))

      const programs = mapCatchupsToDays(response.data)
      const days = Object.keys(programs)
      if (days.length > 0) {
        dispatch(catchupActions.setActiveDay(days[0]))
        dispatch(catchupActions.setPrograms(programs[days[0]]))
      }
      return response.data
    } catch (error) {
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const getCatchupsList = createAsyncThunk(
  GET_CATCHUPS_LIST_SUCCESS,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await service.fetchCatchups()
      return response.data
    } catch (error) {
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const getProgramData = createAsyncThunk(
  'catchup/getProgramData',
  async (
    {
      channel,
      programId,
    }: {
      channel: CatchupChannel
      programId: number | string
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { payload } = await dispatch(changeCatchupChannel(channel))
      const activeProgram = payload.find(
        (item) => item.id === Number(programId)
      )
      dispatch(
        catchupActions.setActiveDay(
          formatDateForCatchupDays(activeProgram.startDate)
        )
      )
      return activeProgram
    } catch (error) {
      console.error(error)
      return rejectWithValue({})
    }
  }
)

export const getRecentlyWatched = createAsyncThunk(
  'catchup/getRecentlyWatched',
  async (
    data: { channelId: number; programId: number }[],
    { rejectWithValue, dispatch }
  ) => {
    try {
      let result

      if (data && data.length) {
        result = await Promise.all(
          data.map(({ channelId, programId }) => {
            return Promise.all([
              fetchCatchupByChannel(channelId), // v3
              service.fetchCatchupByChannel(channelId), // v1
            ]).then(([{ payload }, { data }]) => {
              const catchup = payload?.[0]
              const program = data?.find((program) => program.id === programId)

              if (catchup && program) {
                return {
                  id: program.id,
                  name: program.name,
                  startDate: formatDateForEPG(program.startDate),
                  endDate: formatDateForEPG(program.endDate),
                  channelId,
                  channelNumber: catchup.sortOrder,
                  channelName: catchup.channel.name,
                  channelLogoUrl: catchup.channel.logoUrl,
                }
              }

              return {}
            })
          })
        )
      }

      // remove expires catchups
      return result.filter((catchup) => catchup.id) || []
    } catch (e) {
      return rejectWithValue([])
    }
  }
)
