import React, { memo } from 'react'
import Image from '../../Image'

import '../styles.scss'

export interface ICardProps {
  imageUrl: string
  title: string
  handleClick: () => void
}

const HorizontalCardComponent = (props: ICardProps): JSX.Element => {
  const { imageUrl, title, handleClick } = props
  return (
    <div className='card card-horizontal' onClick={handleClick}>
      <Image
        // className='card-horizontal__image'
        imageUrl={imageUrl}
        defaultUrl={'/img/noContentIcon.svg'}
      />

      <div className='show-on-hover'>{title}</div>
    </div>
  )
}

export const HorizontalCard = memo(HorizontalCardComponent)
