import React from 'react'
import moment from 'moment/min/moment-with-locales'
import { Provider } from 'react-redux'
import { Router, Redirect } from '@reach/router'
import { useStore } from 'effector-react'
import { withTranslation } from 'react-i18next'

import { HeaderContainer } from '../components/common/header/Header'
import { LoginPage } from '../components/login/LoginPage'
import { ToaPage } from '../components/toa/ToaPage'
import { Preloader } from '../components/common/Preloaders'
import { $view } from '/models/app'
import { store } from './configureStore'
import { getRoutes } from './routes'
import { $account } from '/models/account'
import { $pushModule } from '/models/push'
import { CarouselsProvider } from '../components/common/carousel/carousels-context/context'
import { GridsProvider } from '../components/common/grid-cards-layout/grid-context'

import './App.scss'
import '../components/common/breadcrumbs/styles.scss'

export const App = () => {
  const view = useStore($view)
  const account = useStore($account)

  const PushModule = useStore($pushModule)

  if (view === null)
    return (
      <div id='preflight'>
        <Preloader />
      </div>
    )

  if (view === 'login') return <LoginPage />
  if (view === 'toa') return <ToaPage />
  const routes = getRoutes(account)
  const firstEnabledRoute = routes.find((route) => route.enable)

  return (
    <>
      {PushModule && <PushModule.Layer />}
      <HeaderContainer />
      <Router className='content-wrapper'>
        <Redirect noThrow from='*' to={firstEnabledRoute.path} />
        {routes.map((route, i) =>
          route.enable ? <route.component key={route.path} {...route} /> : null
        )}
      </Router>
    </>
  )
}

class AppContainer extends React.Component {
  state = { renderError: false }

  static getDerivedStateFromError() {
    return { renderError: true }
  }

  render() {
    const { t, i18n } = this.props
    moment.locale(i18n.language)

    if (this.state.renderError) {
      return (
        <div id='preflight' className='error'>
          <div>{`${t('Application error')} :(`}</div>
        </div>
      )
    }

    return (
      <Provider store={store}>
        <CarouselsProvider>
          <GridsProvider>
            <App />
          </GridsProvider>
        </CarouselsProvider>
      </Provider>
    )
  }
}

export default withTranslation()(AppContainer)
