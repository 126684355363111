import { createSelector } from 'reselect'
import { getView } from '~/root/rootSelectors'
import { findCategoryById } from '/utils/helpers'
import { Movie, MovieCategory } from '~/components/vod/interfaces'

const getVodView = createSelector(getView, (view) => view.vod)

export const selectVodCategories = createSelector(
  getVodView,
  (view) => view.categories
)

export const selectVodCategoriesContent = createSelector(
  selectVodCategories,
  (categories) => categories.content
)
export const selectPageStateVodCategories = createSelector(
  selectVodCategories,
  ({ page, totalPages }) => ({ page, totalPages })
)

const getActiveMovie = createSelector(getVodView, (view) => view.activeMovie)

export const getVodContent = createSelector(getVodView, (view) => view.content)

export const getVodCategory = createSelector(
  [selectVodCategories, (state, props) => props.categoryId],
  (categories, categoryId) => {
    return categories.content.find(
      (c: MovieCategory) => c.id === Number(categoryId)
    )
  }
)

export const getRecursiveVodCategory = createSelector(
  [selectVodCategories, (state, props) => props.categoryId],
  (categories, categoryId) => {
    return findCategoryById(categories.content, Number(categoryId))
  }
)

export const getVodSubCategory = createSelector(
  [getVodCategory, (state, props) => props],
  (category, props) => {
    const { subCategoryId } = props
    if (!category || !category?.subCategories) return null
    if (!category.subCategories.length) return null

    return category.subCategories.find(
      (c: MovieCategory) => c.id === Number(subCategoryId)
    )
  }
)

export const getVodCategoryByIds = createSelector(
  [selectVodCategories, (state, props) => props],
  (categories, props) => {
    const { categoryId, subCategoryId } = props

    const category = categories.content.find(
      (c: MovieCategory) => c.id === Number(categoryId)
    )
    if (subCategoryId && category.subCategories.length) {
      return category.subCategories.find(
        (c: MovieCategory) => c.id === Number(subCategoryId)
      )
    }
    return category
  }
)

export const getVodSubCategories = createSelector(
  [getVodCategory],
  (category) => {
    if (!category || !category?.subCategories) return []
    if (!category.subCategories.length) return []

    return [...category.subCategories, category] || []
  }
)

export const getVodContentByCategory = createSelector(
  [getVodContent, (state, props) => props.categoryId],
  (content, categoryId) => {
    return content[categoryId] || null
  }
)

export const getVodContentMovies = createSelector(
  [getVodContentByCategory],
  (content) => {
    return content ? content.movies || [] : []
  }
)

export const getVodContentProps = createSelector(
  [getVodContentByCategory],
  (content) => {
    return content ? content.props : null
  }
)

export const getVodBreadcrumbs = createSelector(
  [selectVodCategories, (state, props) => props, getActiveMovie],
  (categories, props, activeMovie) => {
    const { categoryId, subCategoryId, vodId } = props

    const results: unknown[] = []
    const category = categories.find(
      (c: MovieCategory) => c.id === Number(categoryId)
    )
    results.push(category)

    subCategoryId &&
      category?.subCategories.length &&
      results.push(
        category.subCategories.find(
          (c: MovieCategory) => c.id === Number(subCategoryId)
        )
      )

    vodId && results.push(activeMovie)

    return results.filter(Boolean).map((o: any) => ({ id: o.id, name: o.name }))
  }
)

export const getVodsSearch = createSelector([getVodContent], (content) => {
  return content.search || []
})
