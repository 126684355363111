import React from 'react'
import Image from '../../Image'
import { useTranslation } from 'react-i18next'

import '../styles.scss'

interface FeaturedCardProps {
  imageUrl: string | null
  title: string
  ageRatings: string
  releaseDate: number
  handleClick: () => void
}
export const FeaturedCard = (props: FeaturedCardProps): JSX.Element => {
  const { imageUrl, title, ageRatings, releaseDate, handleClick } = props
  const { t } = useTranslation('vod')
  return (
    <div onClick={handleClick} className='card featured-card'>
      <Image imageUrl={imageUrl} defaultUrl={'/img/noContentIcon.svg'} />
      <div className='featured-card__content'>
        <div className='featured-card__title'>{title}</div>
        <div className='featured-card__info'>
          {releaseDate ? (
            <span>{t('Release Date {{year}}', { year: releaseDate })}</span>
          ) : null}
          {ageRatings ? (
            <span>{t('Age Rating {{ageRatings}}', { ageRatings })}</span>
          ) : null}
        </div>
      </div>
    </div>
  )
}
