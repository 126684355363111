import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as service from '/services/api/v3/tvShows'
import {
  GET_RECENT_TVSHOWS_REQUEST,
  CHANGE_TVSHOWS_SEARCH_QUERY,
  GET_TVSHOWS_SEARCH_REQUEST,
  SET_ACTIVE_TVSHOW,
  GET_SEASONS_REQUEST,
  SET_SEASONS,
  SET_ACTIVE_SEASON,
  GET_EPISODES_REQUEST,
  SET_EPISODES,
  SET_ACTIVE_EPISODE,
  GET_EPISODE_URL_REQUEST,
  GET_TVSHOWS_CATEGORIES_REQUEST,
  GET_TVSHOWS_CATEGORY_REQUEST,
} from '../../root/actionTypes'
import {
  showPreloader,
  hidePreloader,
  hidePreloaderImmediately,
} from '/models/preloader'
import type { TvShow, TvShowSeason, TvShowEpisode } from './interfaces'

// new actions
export const updateUrl = createAction<string>('UPDATE_URL')

export const getRecentTvShowsRequest = createAsyncThunk(
  GET_RECENT_TVSHOWS_REQUEST,
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.findRecentTvShows()
      return response.payload
    } catch (error) {
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const getTvShowByIdRequest = createAsyncThunk(
  'GET_TVSHOW_BY_ID',
  async (tvShowId: number, { rejectWithValue }) => {
    try {
      const tvShow = await service
        .findTvShowById(tvShowId)
        .then((response) => response?.payload[0])
      if (tvShow.isTrailerExists) {
        const trailerUrl = await service
          .findTvShowsTrailerUrl(tvShowId)
          .then((response) => response?.payload?.playbackUrl)

        tvShow.trailerUrl = trailerUrl || ''
      }
      return tvShow
    } catch (error) {
      console.error(error)
      return rejectWithValue([])
    }
  }
)

// export const getTvShowsTrailerRequest = createAsyncThunk(
//   'GET_TVSHOWS_TRAILER',
//   async (tvShowId: number, { rejectWithValue }) => {
//     try {
//       const response = await service.findTvShowsTrailerUrl(tvShowId)
//       return response?.payload
//     } catch (error) {
//       console.log(error)
//       return rejectWithValue([])
//     }
//   }
// )

export const changeTvShowsSearch = createAction<[]>('CHANGE_TVSHOWS_SEARCH')
export const changeTvShowsSearchQuery = createAction<string>(
  CHANGE_TVSHOWS_SEARCH_QUERY
)
export const getTvShowsBySearchRequest = createAsyncThunk(
  GET_TVSHOWS_SEARCH_REQUEST,
  async (
    { searchQuery, categoryId }: { searchQuery: string; categoryId: number },
    { rejectWithValue, dispatch }
  ) => {
    // showPreloader()
    dispatch(changeTvShowsSearchQuery(searchQuery))
    try {
      const result = await service.findTvShowsBySearch({
        searchQuery,
        categoryId,
      })
      hidePreloader()
      return result
    } catch (error) {
      console.error(error)
      hidePreloader()
      return rejectWithValue([])
    }
  }
)

export const setActiveTvShow = createAction<TvShow | Record<string, never>>(
  SET_ACTIVE_TVSHOW
)

export const getSeasonsRequest = createAsyncThunk(
  GET_SEASONS_REQUEST,
  async (tvShowId: number, { rejectWithValue }) => {
    try {
      // showPreloader()
      return await service.findSeasons(tvShowId).then(hidePreloader)
    } catch (error) {
      hidePreloader()
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const setSeasons = createAction<TvShowSeason[] | []>(SET_SEASONS)

export const setActiveSeason = createAction<
  TvShowSeason | Record<string, never>
>(SET_ACTIVE_SEASON)

export const getEpisodesRequest = createAsyncThunk(
  GET_EPISODES_REQUEST,
  async (tvShowId: number, { rejectWithValue }) => {
    try {
      // showPreloader()
      return await service.findEpisodes(tvShowId).then(hidePreloader)
    } catch (error) {
      console.error(error)
      hidePreloader()
      return rejectWithValue([])
    }
  }
)

export const getEpisodesBySeasonRequest = createAsyncThunk(
  GET_EPISODES_REQUEST,
  async (
    { tvShowId, seasonId }: { tvShowId: number; seasonId: number },
    { rejectWithValue }
  ) => {
    try {
      // showPreloader()
      return await service
        .findEpisodesBySeason(tvShowId, seasonId)
        .then(hidePreloader)
    } catch (error) {
      hidePreloader()
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const setEpisodes = createAction<TvShowEpisode[] | []>(SET_EPISODES)

export const setActiveEpisode = createAction<
  TvShowEpisode | Record<string, never>
>(SET_ACTIVE_EPISODE)

export const getEpisodeUrlBySeasonRequest = createAsyncThunk(
  GET_EPISODE_URL_REQUEST,
  async (
    {
      tvShowId,
      seasonId,
      episodeId,
    }: { tvShowId: number; seasonId: number; episodeId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await service.findEpisodeUrlBySeason(
        tvShowId,
        seasonId,
        episodeId
      )
      return response.payload
    } catch (error) {
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const getEpisodeUrlSuccess = createAction<string>('UPDATE_EPISODE_URL')
export const getEpisodeUrlRequest = createAsyncThunk(
  GET_EPISODE_URL_REQUEST,
  async (
    { tvShowId, episodeId }: { tvShowId: number; episodeId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await service.findEpisodeUrl(tvShowId, episodeId)
      return response.payload
    } catch (error) {
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const getActiveCategoryRequest = createAction<number>(
  GET_TVSHOWS_CATEGORY_REQUEST
)

export const getTvShowsByCategory = createAsyncThunk(
  'GET_TVSHOWS_BY_CATEGORY_REQUEST',
  async (
    { id, page, count }: { id: number; page: number; count: number },
    { rejectWithValue, dispatch }
  ) => {
    // showPreloader()
    let content = []
    try {
      dispatch(getActiveCategoryRequest(id))
      const response = await service.findTvShowsByCategoryByPage({
        id,
        page,
        count,
      })
      content = response.payload
      // hidePreloader()
      return { activeCategory: id, content }
    } catch (error) {
      // hidePreloader()
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const getCategoriesRequest = createAsyncThunk(
  GET_TVSHOWS_CATEGORIES_REQUEST,
  async (categories: [], { rejectWithValue, dispatch }) => {
    try {
      const categories = await service.findCategoriesPageByPage()
      if (categories && categories.length)
        categories.forEach((c) =>
          dispatch(getTvShowsByCategory({ id: c.id, page: 0, count: 36 }))
        )
      return categories
    } catch (error) {
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const getTvShowsByCategoryByPage = createAsyncThunk(
  'GET_TVSHOWS_BY_CATEGORY_BY_PAGE',
  async (
    { id, page, count }: { id: number; page: number; count: number },
    { rejectWithValue }
  ) => {
    // showPreloader()
    let content = []
    try {
      showPreloader()
      const response = await service.findTvShowsByCategoryByPage({
        id,
        page,
        count,
      })
      content = response.payload
      hidePreloaderImmediately()
      return { activeCategory: id, content }
    } catch (error) {
      hidePreloaderImmediately()
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const resetHistory = createAction('RESET_TV_SHOW_HISTORY')
export const updateHistory = createAction<{ [key: number]: number }>(
  'UPDATE_TV_SHOW_HISTORY'
)
