import React from 'react'
import './styles.scss'

interface NoContentProps {
  icon: JSX.Element
  title: string
  children?: React.ReactNode | React.ReactNodeArray
  subtitle?: string
  className?: string
}
export const NoContent = ({
  icon,
  title,
  children,
  subtitle,
  className = '',
}: NoContentProps): JSX.Element => {
  return (
    <div className={`noContentWrapper ${className}`}>
      {children}
      <div className={'noContent'}>
        {icon}
        <div className={'noContentTitle'}>{title}</div>
        {subtitle && <div className={'noContentSubTitile'}>{subtitle}</div>}
      </div>
    </div>
  )
}
