import { NamedExoticComponent } from 'react'
// import { CatchupPage } from '../components/catchup/CatchupPage'
import { EpgHomePage } from '../components/epg/pages/home'
import { vodRoutes } from '/components/vod/routes'
import { tvShowsRoutes } from '/components/tvShows/routes'
import { ConnectedComponent } from 'react-redux'
import { tvRoutes } from '/components/tv/routes'
import { homeRoutes } from '/components/home/routes'
import { catchupRoutes } from '~/components/catchup/routes'
import { Settings } from '~/components/settings/pages'

export interface Route {
  path: string
  name: string
  component: JSX.Element | ConnectedComponent<any, any> | unknown
  enable: boolean
  main?: boolean
  state?: { shouldStateReset: boolean }
}

type Flags = {
  tvEnabled: boolean
  epgEnabled: boolean
  catchupEnabled: boolean
  vodEnabled: boolean
  tvShowEnabled: boolean
}

export const categoriesPath = 'categories'
export const subCategoriesPath = 'subs'

export function getRoutes({
  tvEnabled,
  epgEnabled,
  catchupEnabled,
  vodEnabled,
  tvShowEnabled,
}: Flags): Route[] {
  return [
    ...homeRoutes(tvEnabled || vodEnabled || tvShowEnabled),
    ...tvRoutes(tvEnabled),
    ...vodRoutes(vodEnabled),
    ...tvShowsRoutes(tvShowEnabled),
    ...catchupRoutes(catchupEnabled),

    {
      path: '/epg',
      name: 'EPG',
      component: EpgHomePage,
      enable: tvEnabled && epgEnabled,
      main: true,
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      enable: true,
      main: true,
    },
  ]
}
