import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
  useLayoutEffect,
} from 'react'

import { GridContext, GridContextType } from './grid-context'
import { isPageScrolledToBottom, debounce } from '/utils/helpers'

import './styles.scss'
interface GridProps {
  id: number
  contextSelector?: string
  page: { number: number; last: boolean }
  children: JSX.Element[]
  getItemsByPage: (id, nextPage) => void
  title?: string
  className?: string
}

export const Grid = (props: GridProps): JSX.Element => {
  const {
    id,
    children,
    page,
    getItemsByPage,
    title,
    className,
    contextSelector,
  } = props
  const { getGridActivePosition, addGrid, updateGrid } = useContext(
    GridContext
  ) as GridContextType

  const gridRef = useRef<HTMLDivElement>(null)
  // const activeCard = getGridActivePosition(contextSelector)

  let currentGrid, cardsInRow, rowHeight, position

  const [gridOffset, setGridOffset] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const [activeCard, setActiveCard] = useState(0)

  useEffect(() => {
    if (contextSelector) {
      currentGrid = gridRef.current
      activeCard === undefined && addGrid(contextSelector)
      setClientHeight(
        document.getElementsByClassName('content')[0].clientHeight
      )

      setGridOffset(currentGrid.getBoundingClientRect().y)
      cardsInRow = Math.floor(
        currentGrid.offsetWidth / currentGrid.children[0]?.offsetWidth
      )
      rowHeight =
        currentGrid.children[cardsInRow]?.getBoundingClientRect().height
    }
  })

  const handleScroll = () => {
    if (contextSelector) {
      position =
        Math.floor((clientHeight - gridOffset) / rowHeight) * cardsInRow
      updateGrid({
        id: contextSelector,
        position:
          position <= children.length - 1 ? position : children.length - 1,
      })
    }
    if (page && isPageScrolledToBottom()) {
      return !page.last && getItemsByPage(id, page.number + 1)
    }
  }

  const debounceHandleScroll = handleScroll

  useEffect(() => {
    window.addEventListener('scroll', debounceHandleScroll)
    return () => window.removeEventListener('scroll', debounceHandleScroll)
  }, [debounceHandleScroll])

  useLayoutEffect(() => {
    if (contextSelector) setActiveCard(getGridActivePosition(contextSelector))
  }, [])

  useEffect(() => {
    if (contextSelector)
      currentGrid.children[activeCard]?.scrollIntoView({ block: 'end' })
  }, [activeCard])

  return (
    <>
      <div className='grid-layout__title'>{title}</div>
      <div className={`grid ${className}`} ref={gridRef}>
        {children}
      </div>
    </>
  )
}
