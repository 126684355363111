import axios from 'axios'
import { tv as api } from '.'
import { isServerUnableProcess, repeatRequest } from '..'

export const fetchCatchupByChannel = (
  channelId: number | string
): Promise<{ payload: [unknown] }> => {
  return axios
    .get(`${api}/${channelId}/catchups`)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        repeatRequest(() => fetchCatchupByChannel(channelId))
      } else {
        throw error
      }
    })
}

export const fetchCatchupPrograms = (
  channelId: number | string,
  catchupId: number | string
): Promise<{ payload: [unknown] }> => {
  return axios
    .get(`${api}/${channelId}/catchups/${catchupId}/programmes`)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        repeatRequest(() => fetchCatchupPrograms(channelId, catchupId))
      } else {
        throw error
      }
    })
}

export const fetchCatchupUrl = (
  channelId: number | string,
  catchupId: number | string,
  programmeId: number | string
): Promise<{ payload: [unknown] }> => {
  return axios
    .get(
      `${api}/${channelId}/catchups/${catchupId}/programmes/${programmeId}/url`
    )
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        repeatRequest(() => fetchCatchupUrl(channelId, catchupId, programmeId))
      } else {
        throw error
      }
    })
}
