import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { CatchupSearchBarContainer } from '../CatchupSearchBar'
import { actions as catchupActions } from '../store'
import { getActiveChannel, getChannels, getDays } from '../store/selectors'
import { useTranslation } from 'react-i18next'
import { CatchupChannel } from '../interfaces/index'
import { Channel } from '~/components/common/channel'
import { Day } from '../components/day'
import { NoContent } from '~/components/common/no-content'
import { PinLockModalContainer } from '../components/CathcupPinLockModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClone,
  faLock,
  faSearch,
  faVideoSlash,
} from '@fortawesome/pro-regular-svg-icons'
import { Program } from '../components/program'
import { isEmpty } from '/utils/helpers'
import { usePrevious, useModal } from '/utils/hooks'

import './styles.scss'
import { HLocation } from '@reach/router'

interface CatchupHomePageProps extends ConnectedProps<typeof connector> {
  location: HLocation<{ shouldStateReset: boolean }>
}

export const CatchupHome = ({
  activeChannel,
  channels,
  searchQ,
  selectChannel,
  days,
  activeDay,
  setActiveDay,
  setActiveProgram,
}: // location,
CatchupHomePageProps): JSX.Element => {
  // const shouldStoreReset = () => {
  //   !activeDay?.length && channels.length && selectChannel(channels?.[0])
  //   location.state.shouldStateReset = false
  // }
  const { t } = useTranslation()
  const daysList = Object.keys(days)
  const { isModalOpened, openModal, closeModal } = useModal()

  // useEffect(() => {
  //   shouldStoreReset()
  // }, [])
  //
  // useEffect(() => {
  //   isEmpty(activeChannel) && channels.length && selectChannel(channels?.[0])
  // }, [channels])
  //
  // useEffect(() => {
  //   activeChannel && !activeDay?.length && setActiveDay(daysList?.[0])
  // }, [activeChannel, days, activeDay])

  const channelsList =
    searchQ && channels?.length
      ? channels.filter((channel) => {
          const channelSearchSrc = `${
            channel.channelNumber
          } ${channel.name.toLowerCase()}`
          return channelSearchSrc.includes(searchQ.toLowerCase())
        })
      : channels

  const prevSearchQ: string = usePrevious(searchQ)

  useEffect(() => {
    if (
      channelsList?.length &&
      (isEmpty(activeChannel) || searchQ || prevSearchQ)
    ) {
      selectChannel(channelsList[0])
    }
  }, [searchQ, channelsList?.length])

  const handleDayClick = (day: string) => {
    setActiveDay(day)
  }

  const handleChannelClick = (channel: CatchupChannel) => {
    selectChannel(channel).then(() => {
      if (channel.locked && !isModalOpened) {
        openModal()
      }
    })
  }

  useEffect(() => {
    if (!activeChannel?.locked && isModalOpened) {
      closeModal()
    }
  }, [activeChannel?.locked])

  return (
    <div className='catchup-page'>
      <div className='page-search-breadcrumbs-wrapper'>
        <CatchupSearchBarContainer />
      </div>
      {!channelsList.length ? (
        <NoContent
          icon={<FontAwesomeIcon icon={searchQ ? faSearch : faVideoSlash} />}
          title={searchQ ? t('No search results found') : t('No content')}
        />
      ) : (
        <div className='catchup-page__content'>
          <div className='channels catchup-page__column'>
            <h2 className='list__title'>{t('Channels')}</h2>
            <div className='channels__list'>
              {channelsList.map((ch: CatchupChannel) => {
                const isActive = ch.id === activeChannel?.id
                const channel = isActive ? activeChannel : ch
                return (
                  <Channel
                    key={ch.id}
                    handleClick={() => handleChannelClick(channel)}
                    isActive={isActive}
                    channel={channel}
                  />
                )
              })}
            </div>
          </div>
          {channelsList.find((ch) => ch.id === activeChannel?.id) &&
          activeChannel?.locked ? (
            <>
              <NoContent
                icon={<FontAwesomeIcon icon={faLock} />}
                title={t(
                  'catchup:Channel is Locked. Please enter PIN Code to watch Catchup List'
                )}
                className={'noDays'}
              />
              {isModalOpened && (
                <PinLockModalContainer
                  autoFocus={true}
                  title={`${activeChannel.channelNumber}. ${activeChannel.name}`}
                  closeModal={closeModal}
                />
              )}
            </>
          ) : daysList.length ? (
            <>
              <div className='days catchup-page__column'>
                <h2 className='list__title'>{t('catchup:Days')}</h2>
                <div className='days__list'>
                  {daysList.map((day) => (
                    <Day
                      key={day}
                      isActive={day === activeDay}
                      handleClick={() => handleDayClick(day)}
                      day={day}
                    />
                  ))}
                </div>
              </div>
              <div className='programs catchup-page__column'>
                <h2 className='list__title'>{t('catchup:Programs')}</h2>
                <div className='programs__list '>
                  {days[activeDay]?.length ? (
                    days[activeDay].map((program) => (
                      <Program
                        key={`${program.name}_${program.startDate}`}
                        program={program}
                        handleClick={() => setActiveProgram(program)}
                      />
                    ))
                  ) : (
                    <span>{'No Programs are available'}</span>
                  )}
                </div>
              </div>
            </>
          ) : (
            <NoContent
              icon={<FontAwesomeIcon icon={faClone} />}
              title={'No Programs are available'}
              className='noDays'
            />
          )}
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    activeChannel: getActiveChannel(state),
    channels: getChannels(state),
    days: getDays(state),
    searchQ: state.views.catchup.search,
    activeDay: state.views.catchup.activeDay,
    activeProgram: state.views.catchup.activeProgram,
    programs: state.views.catchup.programs,
  }
}

const mapDispatchToProps = {
  selectChannel: catchupActions.changeCatchupChannel,
  setPrograms: catchupActions.setPrograms,
  setActiveDay: catchupActions.setActiveDay,
  setActiveProgram: catchupActions.setActiveProgram,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const CatchupHomePage = connector(CatchupHome)
