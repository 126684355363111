import React, { useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, RouteComponentProps } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Player } from '/components/common/player/Player'
import { _ } from '/config'
import { ContentType } from '../../home/constants/index'
import { actions as vodActions } from '/components/vod/store'
import {
  getVodCategory,
  getVodSubCategory,
} from '/components/vod/store/selectors'
import { humanizeTime } from '/utils/date'

import './styles.scss'
import { PlayerPreloader } from '~/components/common/Preloaders'
import { Button } from '~/components/common/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/pro-solid-svg-icons'
import { faHeart } from '@fortawesome/pro-regular-svg-icons'
import { Popup } from '~/components/common/popups'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons'

interface Props extends RouteComponentProps, ConnectedProps<typeof connector> {
  vodId: string // from router
}

function VodDetails(props: Props) {
  const {
    vodId,
    getVODById,
    movie,
    currentCategory,
    setSearchContent,
    currentSubCategory,
    setActiveMovieUrl,
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    getVODById(+vodId)

    return () => {
      setSearchContent([])
      setActiveMovieUrl('')
    }
  }, [])

  const [isModal, setIsModal] = useState(false)
  useEffect(() => {
    const video = document.querySelector('video')
    if (video) !isModal ? video.play() : video.pause()
  }, [isModal])
  const closeModal = () =>
    document.fullscreenElement ? document.exitFullscreen() : setIsModal(false)

  return movie ? (
    <div className={`player-page `}>
      <div className='breadcrumbs'>
        <Link to={'/vods'}>{_('breadcrumbs-movies')}</Link>
        {currentCategory && (
          <Link to={`/vods/categories/${currentCategory?.id}`}>
            {currentCategory.name === 'All' ? t('All') : currentCategory.name}
          </Link>
        )}
        {currentSubCategory && (
          <Link
            to={`/vods/categories/${currentCategory?.id}/subs/${currentSubCategory.id}`}
          >
            {currentSubCategory.name}
          </Link>
        )}
        <span>{movie.name}</span>
      </div>

      <div className='player-page__content'>
        <div className='player-page__player'>
          {movie.url ? (
            <Player
              src={movie.url}
              skin='vod'
              stoppedTime={movie.stoppedTime}
              contentId={movie.id}
              contentType={ContentType.VOD}
              contentTitle={movie.name}
              contentLive={false}
            />
          ) : (
            <PlayerPreloader />
          )}
        </div>

        <div className='player-page__info info'>
          <div className='moovie-player__title'>
            <div className='info__title'>{movie.name}</div>
            <div className={'moovie-buttons'}>
              {movie.trailerUrl && (
                <Button
                  title={
                    <>
                      <FontAwesomeIcon icon={faVideo} />{' '}
                      <span>{t('Trailer')}</span>
                    </>
                  }
                  className={'button-chips button-ghost'}
                  handleClick={() => {
                    setIsModal(true)
                  }}
                />
              )}
              {window.ENV.APP_ADD_TO_FAVORITE_ENABLED && (
                <Button
                  title={
                    <>
                      <FontAwesomeIcon
                        icon={movie.favorite ? faHeartSolid : faHeart}
                      />
                      <span>{t('Add to Favorite')}</span>
                    </>
                  }
                  className={'button-chips button-ghost'}
                  handleClick={() => true}
                />
              )}
            </div>
          </div>
          <p className='short-info'>
            {movie.ageRating ? <span>{movie.ageRating}</span> : null}
            {movie.resolution ? (
              <span>
                {movie.resolution.substring(
                  movie.resolution.length,
                  movie.resolution.length - 2
                )}
              </span>
            ) : null}
            {movie.year ? <span>{movie.year}</span> : null}
            {movie.length && movie.length !== '00:00:00' ? (
              <span>{humanizeTime(movie.length)}</span>
            ) : null}
          </p>

          {movie.directors ? (
            <>
              <h3 className='info__subtitle'>{t('vod:Directors')}</h3>
              <p className='info__paragraph'>{movie.directors}</p>
            </>
          ) : null}
          {movie.stars ? (
            <>
              <h3 className='info__subtitle'>{t('vod:Stars')}</h3>
              <p className='info__paragraph'>{movie.stars}</p>
            </>
          ) : null}
          {movie.description ? (
            <>
              <h3 className='info__subtitle'>{t('vod:Description')}</h3>
              <p className='info__paragraph'>{movie.description}</p>
            </>
          ) : null}
        </div>
      </div>
      {movie.trailerUrl
        ? isModal && (
            <Popup className={'trailer-popup'}>
              <Player
                skin={'vod'}
                src={movie.trailerUrl}
                contentType={ContentType.VOD}
              >
                <Button
                  className={'close-player__button'}
                  title={<FontAwesomeIcon icon={faTimes} />}
                  handleClick={closeModal}
                />
              </Player>
            </Popup>
          )
        : null}
    </div>
  ) : null
}

function mapStateToProps(state, props) {
  return {
    movie: state.views.vod.activeMovie,
    currentCategory: getVodCategory(state, props),
    currentSubCategory: getVodSubCategory(state, props),
  }
}

const mapDispatchToProps = {
  getVODById: vodActions.getVODById,
  setSearchContent: vodActions.setSearchContent,
  setActiveMovieUrl: vodActions.setActiveMovieUrl,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const VodDetailsPage = connector(VodDetails)
