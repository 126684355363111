import React, { useEffect, useCallback, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { VodsCarouselContainer } from '../VodsCarousel.jsx'
import { HorizontalCard } from '~/components/common/cards/horizontal-card/HorizontalCard'
import {
  CardTypes,
  getRandomPoster,
  isPageScrolledToBottom,
} from '/utils/helpers'
import { getHorizontalVODLogo } from '/components/vod/helpers'
import { CategoryCard } from '~/components/common/cards/category-card/CategoryCard'
import { Carousel } from '~/components/common/carousel/Carousel'
import { VodsSearchBarContainer } from '../VodsSearchBar'
import {
  getVodsSearch,
  selectPageStateVodCategories,
  selectVodCategoriesContent,
  getVodContent,
} from '/components/vod/store/selectors'
import { Grid } from '/components/common/grid-cards-layout'
import { NoContent } from '/components/common/no-content'
import { actions as vodActions } from '../store'
import { _ } from '/config'

import { MovieCategory } from '~/components/vod/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faVideoSlash } from '@fortawesome/pro-regular-svg-icons'

import './styles.scss'

function VodHome(props: ConnectedProps<typeof connector>) {
  const {
    categories,
    categoriesPageState,
    search,
    content,
    carousel,
    loadContent,
    setSearchContent,
    getVodsCategories,
  } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  const maxLength = 36

  const [q, setQ] = useState<string>('')

  useEffect(() => {
    return () => {
      setSearchContent([])
    }
  }, [])

  useEffect(() => {
    if (categories.length) {
      categories.forEach((category: MovieCategory) => {
        const { id } = category
        const isCashed = Boolean(content[id])

        isCashed || loadContent({ categoryId: id, count: maxLength, page: 0 })
      })
    }
  }, [categories])

  const handleMovieClick = useCallback(
    (id: number, categoryId = 0) => {
      return navigate(`/vods/categories/${categoryId}/${id}`)
    },
    [content]
  )

  const handleCategoryClick = useCallback(
    (id: number, subCategoryId?: number) => () => {
      let url = `/vods/categories/${id}`
      if (subCategoryId) url = `/vods/categories/${id}/subs/${subCategoryId}`

      return navigate(url)
    },
    [categories]
  )

  const handleScroll = () => {
    let { page, totalPages } = categoriesPageState
    if (page < totalPages - 1 && isPageScrolledToBottom()) {
      const newPage = ++page
      getVodsCategories({ page: newPage })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <>
      <div className='page-search-breadcrumbs-wrapper'>
        {q && (
          <div className='breadcrumbs'>
            <span>{t(_('breadcrumbs-movies'))}</span>
          </div>
        )}
        <VodsSearchBarContainer callback={setQ} />
      </div>
      {q ? (
        search.length ? (
          <Grid
            id={0}
            page={{ number: 0, last: true }}
            getItemsByPage={() => null}
            title={t('Search Result ({{length}})', { length: search.length })}
            className={'base-cards'}
          >
            {search.map((item) => {
              const imageUrl = item.imageHorizontalUrl
                ? getHorizontalVODLogo(item.imageHorizontalUrl)
                : getHorizontalVODLogo(item.imageUrl)
              return (
                <HorizontalCard
                  key={`${item.id}${item.name}`}
                  title={item.name}
                  imageUrl={imageUrl || ''}
                  handleClick={() => handleMovieClick(item.id, 0)}
                />
              )
            })}
          </Grid>
        ) : (
          <NoContent
            icon={<FontAwesomeIcon icon={faSearch} className='icon' />}
            title={t('No search results found')}
          />
        )
      ) : (!carousel?.enabledOnModulePage || !carousel?.content?.length) &&
        !categories.filter(
          (category) =>
            content &&
            content[category.id]?.movies &&
            content[category.id].movies.length
        ).length ? (
        <NoContent
          icon={<FontAwesomeIcon icon={faVideoSlash} />}
          title={t('No content')}
        />
      ) : (
        <div className='page__content'>
          {carousel?.enabledOnModulePage && (
            <VodsCarouselContainer
              id={`vodFeatured`}
              cardType={CardTypes.featuredCard}
              handleMovieClick={handleMovieClick}
            />
          )}
          {categories.map((category: MovieCategory) => {
            const list = (category.subCategories || []).concat(
              content?.[category.id]?.movies || []
            )
            return list.length ? (
              <Carousel
                id={`vod_${category.id}`}
                key={category.id}
                carouselTitle={
                  category.name === 'All' ? t('All') : category.name
                }
                showAllOption={
                  content[category.id]?.props.totalElements > maxLength ||
                  list.length > maxLength
                }
                handleCategoryClick={handleCategoryClick(category.id)}
              >
                {list.slice(0, maxLength).map((item: any) => {
                  const isCategory = !Object.prototype.hasOwnProperty.call(
                    item,
                    'imageHorizontalUrl'
                  )
                  const imageUrl = isCategory
                    ? getRandomPoster()
                    : item.imageHorizontalUrl
                    ? getHorizontalVODLogo(item.imageHorizontalUrl)
                    : getHorizontalVODLogo(item.imageUrl)

                  return isCategory ? (
                    <CategoryCard
                      key={`${item.id}${item.name}`}
                      title={item.name}
                      imageUrl={imageUrl}
                      showOnHoverTitle={``}
                      className={'category-card '}
                      handleClick={handleCategoryClick(category.id, item.id)}
                    />
                  ) : (
                    <HorizontalCard
                      key={`${item.id}${item.name}`}
                      title={item.name}
                      imageUrl={imageUrl || ''}
                      handleClick={() => handleMovieClick(item.id, category.id)}
                    />
                  )
                })}
              </Carousel>
            ) : null
          })}
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    categories: selectVodCategoriesContent(state),
    categoriesPageState: selectPageStateVodCategories(state),
    content: getVodContent(state),
    carousel: state.views.tv.carousels?.find((item) => item.type === 'VOD'),
    search: getVodsSearch(state),
  }
}

const mapDispatchToProps = {
  loadContent: vodActions.getVodsByCategory,
  getVodsCategories: vodActions.getCategories,
  setCategoriesPageState: vodActions.setCategoriesPageState,
  setSearchContent: vodActions.setSearchContent,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const VodHomePage = connector(VodHome)
