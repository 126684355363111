import React, { useCallback, useContext, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useNavigate, useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Carousel } from '/components/common/carousel/Carousel'
import { getHorizontalVODLogo } from '/components/vod/helpers'
import { HorizontalCard } from '/components/common/cards/horizontal-card/HorizontalCard'
import { Grid } from '/components/common/grid-cards-layout'
import { isPageScrolledToBottom, debounce } from '/utils/helpers'
import { VodsSearchBarContainer } from '../VodsSearchBar'
import { NoContent } from '/components/common/no-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { _ } from '/config'

import { Movie, MovieCategory } from '~/components/vod/interfaces'

import {
  getRecursiveVodCategory,
  // getVodCategory,
  getVodContent,
  getVodContentMovies,
  getVodContentProps,
  getVodsSearch,
  getVodSubCategories,
  getVodSubCategory,
} from '/components/vod/store/selectors'
import { actions as vodActions } from '/components/vod/store'
import { CarouselsContext } from '~/components/common/carousel/carousels-context/context'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  categoryId: string // from router
  subCategoryId?: string // from router
}

export const VodCategory = (props: Props) => {
  const {
    categoryId,
    currentCategory,
    currentSubCategory,
    subCategoryId,
    subCategories,
    movies,
    settings,
    content,
    search,
    loadContent,
    updateContent,
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [q, setQ] = useState<string>('')
  const { t } = useTranslation()

  const SCROLL_DEBOUNCE_TIMEOUT = 500
  // start carousel pagination
  // may be move to other component or hook
  // -
  const maxLength = 36

  const [pagination, setPagination] = useState({ page: 1, total: 0, offset: 5 })

  // set pagination data
  useEffect(() => {
    if (subCategories.length && content) {
      setPagination({ ...pagination, page: 1, total: subCategories.length })

      subCategories.slice(0, pagination.offset).forEach((category) => {
        const isCashed = Boolean(content[category.id])
        isCashed ||
          loadContent({
            page: 0,
            categoryId: Number(category.id),
            count: maxLength,
          })
      })
    }
  }, [subCategories])

  const handleScroll = debounce(() => {
    const pages = Math.ceil(pagination.total / pagination.offset)

    if (pagination.page < pages && isPageScrolledToBottom()) {
      const newPage = ++pagination.page
      setPagination({ ...pagination, page: newPage })

      subCategories
        .slice(0, pagination.offset * newPage)
        .forEach((category) => {
          const isCashed = Boolean(content[category.id])
          isCashed ||
            loadContent({
              page: 0,
              categoryId: Number(category.id),
              count: maxLength,
            })
        })
    }
  }, SCROLL_DEBOUNCE_TIMEOUT)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])
  // -
  // end carousel pagination

  useEffect(() => {
    if (!movies.length) {
      loadContent({
        page: 0,
        categoryId: Number(subCategoryId || categoryId),
        count: maxLength,
      })
    }
  }, [])

  const handleMovieClick = useCallback(
    (id: number, category?: MovieCategory) => {
      let url = `${location.pathname}/${id}`

      if (category && !category.subCategories)
        url = `${location.pathname}/subs/${category.id}/${id}`

      return navigate(url)
    },
    [movies]
  )

  const handleCategoryClick = useCallback(
    (id: number) => () => {
      let url = `/vods/categories/${categoryId}`
      if (id) url += `/subs/${id}`

      return navigate(url)
    },
    [categoryId, subCategoryId]
  )

  const getItemsByPage = useCallback(
    (id, page) => {
      updateContent({
        categoryId: Number(subCategoryId || id),
        page,
        count: maxLength,
      })
    },
    [settings]
  )

  return (
    <div className='row content page-vods nomargin'>
      <div className='page-search-breadcrumbs-wrapper'>
        <div className='breadcrumbs'>
          <Link to={'/vods'}>{t(_('breadcrumbs-movies'))}</Link>
          {currentCategory && (
            <Link to={`/vods/categories/${currentCategory?.id}`}>
              {currentCategory.name === 'All' ? t('All') : currentCategory.name}
            </Link>
          )}
          {currentSubCategory && (
            <Link
              to={`/vods/categories/${currentCategory?.id}/subs/${currentSubCategory.id}`}
            >
              {currentSubCategory.name}
            </Link>
          )}
        </div>
        <VodsSearchBarContainer callback={setQ} />
      </div>
      {q ? (
        search && search.length ? (
          <Grid
            id={0}
            page={{ number: 0, last: true }}
            getItemsByPage={() => null}
            title={t('Search Result ({{length}})', { length: search.length })}
            className={'base-cards'}
          >
            {search.map((item) => {
              const imageUrl = item.imageHorizontalUrl
                ? getHorizontalVODLogo(item.imageHorizontalUrl)
                : getHorizontalVODLogo(item.imageUrl)
              return (
                <HorizontalCard
                  key={`${item.id}${item.name}`}
                  title={item.name}
                  imageUrl={imageUrl || ''}
                  handleClick={() => handleMovieClick(item.id)}
                />
              )
            })}
          </Grid>
        ) : (
          <NoContent
            icon={<FontAwesomeIcon icon={faSearch} className='icon' />}
            title={t('No search results found')}
          />
        )
      ) : subCategories && subCategories.length && !subCategoryId ? (
        subCategories
          .slice(0, pagination.offset * pagination.page)
          .map((category: MovieCategory) => (
            <Carousel
              id={`vod_${category.id}`}
              key={category.id}
              carouselTitle={category.name}
              showAllOption={
                content?.[category.id]?.props?.totalElements > maxLength
              }
              handleCategoryClick={handleCategoryClick(category.id)}
            >
              {(content[category.id]?.movies || [])
                .slice(0, maxLength)
                .map((item) => {
                  const imageUrl = item.imageHorizontalUrl
                    ? getHorizontalVODLogo(item.imageHorizontalUrl)
                    : getHorizontalVODLogo(item.imageUrl)

                  return (
                    <HorizontalCard
                      key={`${item.id}${item.name}`}
                      title={item.name}
                      imageUrl={imageUrl || ''}
                      handleClick={() => handleMovieClick(item.id, category)}
                    />
                  )
                })}
            </Carousel>
          ))
      ) : (
        settings && (
          <Grid
            id={Number(subCategoryId || categoryId) || 0}
            contextSelector={`vod_${subCategoryId || categoryId || 'all'}`}
            page={{ number: settings.curNumber, last: settings.last }}
            getItemsByPage={getItemsByPage}
            title={
              currentCategory?.name === 'All'
                ? t('All')
                : currentSubCategory?.name || currentCategory?.name
            }
            className={'base-cards'}
          >
            {movies?.map((item) => {
              const imageUrl = item.imageHorizontalUrl
                ? getHorizontalVODLogo(item.imageHorizontalUrl)
                : getHorizontalVODLogo(item.imageUrl)
              return (
                <HorizontalCard
                  key={`${item.id}${item.name}`}
                  title={item.name}
                  imageUrl={imageUrl || ''}
                  handleClick={() => handleMovieClick(item.id)}
                />
              )
            })}
          </Grid>
        )
      )}
    </div>
  )
}

function mapStateToProps(state, { categoryId, subCategoryId }) {
  return {
    subCategories: getVodSubCategories(state, {
      categoryId: subCategoryId || categoryId,
    }),
    movies: getVodContentMovies(state, {
      categoryId: subCategoryId || categoryId,
    }),
    settings: getVodContentProps(state, {
      categoryId: subCategoryId || categoryId,
    }),
    content: getVodContent(state),
    currentCategory: getRecursiveVodCategory(state, { categoryId }),
    currentSubCategory: getVodSubCategory(state, { categoryId, subCategoryId }),
    search: getVodsSearch(state),
  }
}

const mapDispatchToProps = {
  loadContent: vodActions.getVodsByCategory,
  updateContent: vodActions.updateVodsByCategory,
  setSearchContent: vodActions.setSearchContent,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const VodCategoryPage = connector(VodCategory)
