import { useEffect, useRef, useState } from 'react'

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useModal(): {
  isModalOpened: boolean
  openModal: () => void
  closeModal: () => void
} {
  const [isModalOpened, setModalOpen] = useState<boolean>(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return { isModalOpened, openModal, closeModal }
}
