import axios from 'axios'
import { refreshSessionFx } from '/models/session'

export const api = '/api/media'

class ResponseError extends Error {
  constructor(message, response) {
    super(message)
    this.name = 'ResponseError'
    this.response = response
  }
}

/**
 * Axios configurator function; add interceptors and base URL for request
 * @return {undefined}
 */
export function configureAxios() {
  axios.defaults.baseURL = '/wbs/web'
  axios.defaults.withCredentials = true

  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.status === 'ERROR') {
        throw new ResponseError(response.data.message, response)
      }

      return response
    },
    (error) => {
      const status = error?.response?.status
      if (status === 401) {
        if (!error?.response?.config?.url?.endsWith('/refresh')) {
          refreshSessionFx()
        }
      }

      throw error
    }
  )

  /*
  axios.interceptors.response.use(
    (response) => {
      // store.dispatch(getErrorModal())

      if (
        response.data.status === 'ERROR' &&
        response.data.errorCode === 'EC300'
      ) {
        store.dispatch(loginError())
        window.location.hash = '#/login'
      }

      return response
    },
    (error) => {
      const status = Number(error.toString().split('code ').pop())

      if (status === 401) {
        store.dispatch(loginError())
        window.location.hash = '#/login'
      } else if (status === 503) {
        store.dispatch(getErrorModal(503))
      }

      throw error
    }
  )
  */
}

/**
 * Check error code and return true if it 503
 * @param {Number} axiosErrorCode - error code promise catch
 * @return {Boolean} status
 */
export function isServerUnableProcess(axiosErrorCode) {
  return axiosErrorCode === 503
}

/**
 * Check error code and return true if it 404
 * @param {Number} axiosErrorCode - error code promise catch
 * @return {Boolean} status
 */
export function isApiUnavailable(axiosErrorCode) {
  return axiosErrorCode === 404
}

/**
 * Repeat request, after 3000 milisecond if response code was 503
 * @param {Function} callback - fetch server data function
 * @return {Function} timeout 1000ms
 */
export function repeatRequest(callback) {
  return setTimeout(() => callback(), 3000)
}
