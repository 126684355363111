// ****************************************** /
// ******************* TVSHOWS ************** /
// ****************************************** /

export const GET_TVSHOWS_REQUEST = 'GET_TVSHOWS_REQUEST'
export const GET_TVSHOWS_SUCCESS = 'GET_TVSHOWS_SUCCESS'
export const GET_TVSHOWS_ERROR = 'GET_TVSHOWS_ERROR'

export const GET_RECENT_TVSHOWS_REQUEST = 'GET_RECENT_TVSHOWS_REQUEST'
export const GET_RECENT_TVSHOWS_SUCCESS = 'GET_RECENT_TVSHOWS_SUCCESS'
export const GET_RECENT_TVSHOWS_ERROR = 'GET_RECENT_TVSHOWS_ERROR'

export const SET_ACTIVE_TVSHOW = 'SET_ACTIVE_TVSHOW'

export const GET_TVSHOWS_SEARCH_REQUEST = 'GET_TVSHOWS_SEARCH_REQUEST'
export const GET_TVSHOWS_SEARCH_SUCCESS = 'GET_TVSHOWS_SEARCH_SUCCESS'
export const GET_TVSHOWS_SEARCH_ERROR = 'GET_TVSHOWS_SEARCH_ERROR'

export const CHANGE_TVSHOWS_SEARCH_QUERY = 'CHANGE_TVSHOWS_SEARCH_QUERY'

export const GET_SEASONS_REQUEST = 'GET_SEASONS_REQUEST'
export const GET_SEASONS_SUCCESS = 'GET_SEASONS_SUCCESS'
export const GET_SEASONS_ERROR = 'GET_SEASONS_ERROR'

export const SET_SEASONS = 'SET_SEASONS'
export const SET_ACTIVE_SEASON = 'SET_ACTIVE_SEASON'

export const GET_EPISODES_REQUEST = 'GET_EPISODES_REQUEST'
export const GET_EPISODES_SUCCESS = 'GET_EPISODES_SUCCESS'
export const GET_EPISODES_ERROR = 'GET_EPISODES_ERROR'

export const SET_EPISODES = 'SET_EPISODES'
export const SET_ACTIVE_EPISODE = 'SET_ACTIVE_EPISODE'

export const GET_EPISODE_URL_REQUEST = 'GET_EPISODE_URL_REQUEST'
export const GET_EPISODE_URL_SUCCESS = 'GET_EPISODE_URL_SUCCESS'
export const GET_EPISODE_URL_ERROR = 'GET_EPISODE_URL_ERROR'

export const GET_TVSHOWS_CATEGORIES_REQUEST = 'GET_TVSHOWS_CATEGORIES_REQUEST'
export const GET_TVSHOWS_CATEGORIES_SUCCESS = 'GET_TVSHOWS_CATEGORIES_SUCCESS'
export const GET_TVSHOWS_CATEGORIES_ERROR = 'GET_TVSHOWS_CATEGORIES_ERROR'

export const GET_TVSHOWS_CATEGORY_REQUEST = 'GET_TVSHOWS_CATEGORY_REQUEST'
export const GET_TVSHOWS_CATEGORY_SUCCESS = 'GET_TVSHOWS_CATEGORY_SUCCESS'
export const GET_TVSHOWS_CATEGORY_ERROR = 'GET_TVSHOWS_CATEGORY_ERROR'

// ****************************************** /
// ****************** RADIO ***************** /
// ****************************************** /

export const GET_RADIO_CATEGORIES_REQUEST = 'GET_RADIO_CATEGORIES_REQUEST'
export const GET_RADIO_CATEGORIES_SUCCESS = 'GET_RADIO_CATEGORIES_SUCCESS'
export const GET_RADIO_CATEGORIES_ERROR = 'GET_RADIO_CATEGORIES_ERROR'

export const GET_RADIO_CATEGORY_REQUEST = 'GET_RADIO_CATEGORY_REQUEST'
export const GET_RADIO_CATEGORY_SUCCESS = 'GET_RADIO_CATEGORY_SUCCESS'
export const GET_RADIO_CATEGORY_ERROR = 'GET_RADIO_CATEGORY_ERROR'

export const GET_RADIO_REQUEST = 'GET_RADIO_REQUEST'
export const GET_RADIO_SUCCESS = 'GET_RADIO_SUCCESS'
export const GET_RADIO_ERROR = 'GET_RADIO_ERROR'

export const SET_RADIO_STATUS = 'SET_RADIO_STATUS'

export const CHANGE_RADIO_SEARCH_FIELD = 'CHANGE_RADIO_SEARCH_FIELD'

// ****************************************** /
// *************** CATCHUPS ***************** /
// ****************************************** /

export const GET_CATCHUPS_LIST_REQUEST = 'GET_CATCHUPS_LIST_REQUEST'
export const GET_CATCHUPS_LIST_SUCCESS = 'GET_CATCHUPS_LIST_SUCCESS'
export const GET_CATCHUPS_LIST_ERROR = 'GET_CATCHUPS_LIST_ERROR'

export const GET_CATCHUP_REQUEST = 'GET_CATCHUP_REQUEST'
export const GET_CATCHUP_SUCCESS = 'GET_CATCHUP_SUCCESS'
export const GET_CATCHUP_ERROR = 'GET_CATCHUP_ERROR'

export const GET_PROGRAMS = 'GET_PROGRAMS'
export const GET_ACTIVE_DAY = 'GET_ACTIVE_DAY'
export const GET_ACTIVE_PROGRAM = 'GET_ACTIVE_PROGRAM'

export const CHANGE_CATCHUPS_SEARCH_FIELD = 'CHANGE_CATCHUPS_SEARCH_FIELD'
export const ADD_RECENTLY_WATCHED_CATCHUPS = 'ADD_RECENTLY_WATCHED_CATCHUPS'

// ****************************************** /
// ****************** EPG ******************* /
// ****************************************** /

export const GET_ALL_CHANNELS_REQUEST = 'GET_EPG_ALL_CHANNELS_REQUEST'
export const GET_ALL_CHANNELS_ERROR = 'GET_EPG_ALL_CHANNELS_ERROR'
export const GET_ALL_CHANNELS_SUCCESS = 'GET_EPG_ALL_CHANNELS_SUCCESS'

export const CHANGE_EPG_SEARCH_FIELD = 'CHANGE_EPG_SEARCH_FIELD'

export const GET_FEATURED_CAROUSELS_REQUEST = 'GET_FEATURED_CAROUSELS_REQUEST'
export const GET_FEATURED_CAROUSELS_SUCCESS = 'GET_FEATURED_CAROUSELS_SUCCESS'
export const GET_FEATURED_CAROUSELS_ERROR = 'GET_FEATURED_CAROUSELS_ERROR'

export const GET_BANNER_CAROUSEL_REQUEST = 'GET_BANNER_CAROUSEL_REQUEST'
export const GET_BANNER_CAROUSEL_SUCCESS = 'GET_BANNER_CAROUSEL_SUCCESS'
export const GET_BANNER_CAROUSEL_ERROR = 'GET_BANNER_CAROUSEL_ERROR'

export const SET_ACTIVE_CAROUSEL = 'SET_ACTIVE_CAROUSEL'
