import cn from 'classnames'
import React, { useState, useEffect } from 'react'
import { Link, Location } from '@reach/router'
import { connect } from 'react-redux'
import { useStore } from 'effector-react'
import { $account } from '/models/account'
import { useTranslation } from 'react-i18next'
import { GlobalPreloader } from '../Preloaders'
import { Notifications } from './Notifications'

import ErrorModal from './ErrorModal'
import { getRoutes } from '~/root/routes'
import { getExpirationTime } from '~/utils/date'

import { customize } from '/config'

import './Header.scss'
import './Menu.scss'

/**
 * Navigation menu bar component
 */
const MenuBar = ({ account, pathname }) => {
  const { t } = useTranslation()
  const items = getRoutes(account)
  const [isHeaderActive, setActiveHeader] = useState(false)
  const [isMenuOpened, setMenuOpened] = useState(false)
  const packageName = account?.packageName
  const logoUrl = account?.logoUrl

  const handleHeader = () => {
    setActiveHeader(
      !!document.documentElement.scrollTop || !!document.body.scrollTop
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', handleHeader)
    return () => window.removeEventListener('scroll', handleHeader)
  }, [handleHeader])

  const closeMenu = () => setMenuOpened(false)

  let expirationTime
  if (account?.expirationTime) {
    expirationTime = getExpirationTime(account.expirationTime)
  }
  const activePage = items.find((item) => pathname.includes(item.path))

  // TODO: account has `logoUrl`, should we use it?
  return (
    <div
      className={`menubar__overlay
        ${isMenuOpened ? 'opened' : ''}
        ${isHeaderActive ? 'active' : ''}
        `}
    >
      <div
        className={`menubar
         unselectable`}
      >
        <div className='menubar__logo'>
          <img
            className='menubar__logo-image'
            src={(logoUrl?.length && logoUrl) || customize.images.logo}
            alt='Logo'
          />
        </div>

        <div
          className={`mobile-menu__toggler  ${isMenuOpened ? 'open' : ''}`}
          onClick={() => setMenuOpened(!isMenuOpened)}
        >
          <div className='burger-icon'>
            <span className='line'></span>
            <span className='line'></span>
            <span className='line'></span>
          </div>
          {activePage ? (
            <div className='page-title'>{t(activePage.name)}</div>
          ) : null}
        </div>
      </div>
      <div
        className={`navbar ${isMenuOpened ? 'open' : ''} ${
          !logoUrl || !logoUrl.length ? 'noLogo' : ''
        }`}
      >
        <ul className='navbar__inner'>
          {items.map((item) => {
            return item.enable && item.main ? (
              <li
                key={item.path}
                className={cn('navbar__item', {})}
                onClick={isMenuOpened ? closeMenu : undefined}
              >
                <Link
                  to={item.path}
                  className={cn('link', 'navbar__link', {
                    'navbar__link-active':
                      item.path.indexOf(pathname.split('/')[1]) >= 0,
                  })}
                  state={item.state}
                >
                  {t(item.name)}
                </Link>
              </li>
            ) : null
          })}
        </ul>
        <div className='menubar__info'>
          {`${packageName} ${t('settings:Expires {{expirationTime}}', {
            expirationTime,
          })}`}
        </div>
      </div>
    </div>
  )
}

/**
 * Main header component
 */
export const Header = ({ error }) => {
  const account = useStore($account)
  return (
    <header>
      <GlobalPreloader />
      <Notifications />

      <Location>
        {({ location }) => (
          <MenuBar account={account} pathname={location.pathname} />
        )}
      </Location>

      {error && <ErrorModal error={error} />}
    </header>
  )
}

function mapStateToProps(state) {
  return {
    error: undefined, // state.account.error, // FIXME:
  }
}

export const HeaderContainer = connect(mapStateToProps)(Header)
