import moment from 'moment/min/moment-with-locales'

/**
 * @returns {string} Date with valide format [for EPG POST request]
 */
function getTime() {
  const time = moment()
  const timezone = -parseInt(time.format('Z'), 10)

  time.set('minute', 0)
  time.set('second', 0)
  time.set('millisecond', 0)
  time.add(timezone, 'hours')

  return time
}

/**
 * Generate time interval for valide EPG server request from params
 * @param {number} hours - count of hours in time interval
 * @returns {object}: {fromDate} {toDate}
 */
export function generateEPGTimeInterval(hours: number) {
  return {
    fromDate: getTime().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
    toDate: getTime().add(hours, 'hours').format('YYYY-MM-DDTHH:mm:ss') + 'Z',
  }
}

/**
 * Return percentage [current] from interval [begin, end]
 * @param {string} begin - start Date
 * @param {string} end - stop Date
 * @returns {number} percent
 */
export function getPercentageFromDate(begin: string, end: string) {
  const start = +moment(begin).format('x')
  const stop = +moment(end).format('x') - start
  const current = +moment().format('x') - start

  return Math.round((current * 100) / stop)
}

// ****************************************** /
// ***** FORMAT DATE COMPONENTS HELPERS ***** /
// ****************************************** /

export function formatDateToMiliseconds(ISOString: string) {
  return moment(ISOString).format('x')
}

/**
 * Convert Date to time in 24-hours format
 *
 * @param   {Object} date
 * @returns {string} time in 24-hours format
 */

export const convertDateToTime24h = (date) => moment(date).format('HH:mm')

/**
 * Format iso string like '2021-10-11T17:00:00Z' to hours in 24-hour format and minutes
 * @param ISOString
 * @returns string
 */
export const formatDate = (ISOString: string): string => {
  return moment(ISOString).utc().format('HH:mm')
}

export function formatDateForEPG(ISOString: string) {
  return moment(ISOString).format('HH:mm')
}

export function formatDateForCatchupDays(ISOString: string): string {
  return moment(ISOString).format('YYYY-MM-DD')
}

export function formatDateToWeekDay(string: string): string {
  return moment(string).format('D dddd')
}

export function formatDateToWeekDayShort(string: string): string {
  return moment(string).format('dd')
}

export function formatDateForAnnouncement(ISOString: string) {
  return `${moment(ISOString).format('YYYY-MM-DD')} at ${moment(
    ISOString
  ).format('HH:mm:ss')}`
}

export function getDay(ISOString: string): string {
  return `${moment(ISOString).format('D MMMM')}`
}

/**
 * Convert Unix epoch timestamp in seconds to date format DD MMM YYYY.
 *
 * @param   {number} time - Unix time in seconds.
 * @returns {string} Date.
 */
export function convertUnixTimeToDate(time: number): string {
  return moment.unix(time).format('DD MMM YYYY')
}

export const getExpirationTime = (time: string): string => {
  let iso8601 = time
  if (!iso8601.endsWith('Z')) iso8601 += 'Z'
  return moment(iso8601).fromNow()
}

export const getTimeFromNow = (date: string) => moment(date).fromNow(true)

/**
 * Convert timestamp in milliseconds to date and returns strings like 'September 16', 'Today' or 'Yesterday'
 *
 * @param time in milliseconds
 * @returns day
 */
export const getDate = (time: number): string => {
  const today = 'Today'
  const yesterday = 'Yesterday'
  const day = moment(time).calendar()
  const date = moment(time).format('MMMM D')

  return day.includes(today)
    ? today
    : day.includes(yesterday)
    ? yesterday
    : date
}

/**
 * Function convert time to human like format and delete zero values
 * for example, 1h 22m 4s or 10h 5m
 *
 * @param time
 * @returns formatted time
 */
export const humanizeTime = (time: string): string => {
  if (!time) return ''

  const formattedTime = moment(time, 'H:m:s').format('H[h] m[m] s[s]')
  return formattedTime.replace(/\b0(h|m|s)/g, '')
}
