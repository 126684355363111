import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../common/carousel/Carousel'
import { CategoryCard } from '../common/cards/category-card/CategoryCard'
import { CardTypes } from '/utils/helpers'
import { useNavigate } from '@reach/router'

const TvShowCarouselContainer = ({
  content,
  cardType,
  handleMovieClick = null,
  id,
}) => {
  const navigate = useNavigate()
  const maxLength = 36
  const { t } = useTranslation('home')

  const handleClick = (c) => {
    handleMovieClick && handleMovieClick(c)
    navigate(`/shows/categories/0/${c.id}`)
  }

  return content && content.tvShows && content.tvShows.length ? (
    <Carousel
      categoryId={'lastAddedSeries'}
      key={"'lastAddedSeries'"}
      carouselTitle={t('Last added TV Series')}
      className='featured'
      showAllOption={false}
      id={id}
    >
      {content.tvShows
        .slice(0, maxLength)
        .sort((a, b) => (a.updated > b.updated && 1) || -1)
        .map((c) => {
          return (
            <CategoryCard
              key={`${c.id}/${c.name}`}
              path={''}
              title={''}
              className={
                cardType === CardTypes.tvChannelCard
                  ? 'tv-channel-card'
                  : 'tv-channel-card_featured'
              }
              showOnHoverTitle={c.name}
              imageUrl={c.landscapeImageUrl}
              handleClick={() => handleClick(c)}
            />
          )
        })}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    content: state.views.tvShows.content,
  }
}

const mapDispatchToProps = {}

export const TvShowsCarouselLastAdded = connect(
  mapStateToProps,
  mapDispatchToProps
)(TvShowCarouselContainer)
