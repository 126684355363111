import React, { memo } from 'react'
import Image from '../../Image'

import '../styles.scss'
import { getChannelLogo } from '/utils/helpers'

interface HorizontalInfoCardProps {
  className?: string
  name: string
  channelNumber: string
  channelName: string
  channelLogoUrl: string
  startDate: string
  endDate: string
  handleClick: () => void
}

export const HorizontalInfoCardComponent = (
  props: HorizontalInfoCardProps
): JSX.Element => {
  const {
    name,
    channelNumber,
    channelName,
    channelLogoUrl,
    startDate,
    endDate,
    className,
    handleClick,
  } = props
  return (
    <div
      className={`card horizontal-info-card ${className || ''}`}
      onClick={handleClick}
    >
      <div className='horizontal-info-card__top-part'>
        <div className='horizontal-info-card__image'>
          <Image
            imageUrl={getChannelLogo(channelLogoUrl)}
            defaultUrl={'/img/noLogo.svg'}
          />
        </div>
        <p className='horizontal-info-card__channel truncated'>
          {channelNumber}. {channelName}
        </p>
      </div>
      <p className='horizontal-info-card__title truncated'>{name}</p>
      <div className='horizontal-info-card__time'>{`${startDate} - ${endDate}`}</div>
    </div>
  )
}

export const HorizontalInfoCard = memo(HorizontalInfoCardComponent)
