import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import {
  getChannelLogo,
  getChannelLogoSmall,
  getRandomLockedChannelPoster,
} from '~/utils/helpers'
import Image from '../Image'
import './styles.scss'

interface Channel {
  id: number
  name: string
  epgId: string
  channelNumber: number
  locked: boolean
  logoUrl: string
  orderType: string
  resolution: string
}

interface ChannelProps {
  handleClick: () => void
  channel: Channel
  isActive: boolean
}

export const Channel = ({
  handleClick,
  isActive,
  channel,
}: ChannelProps): JSX.Element => {
  const { locked, channelNumber, name, logoUrl } = channel
  const lockedImage =
    locked && useMemo(() => getRandomLockedChannelPoster(), [channel.id])
  return (
    <div
      className={`channel ${isActive ? 'active' : ''}`}
      onClick={handleClick}
    >
      <div className={`channel__logo `}>
        {locked ? (
          <>
            {lockedImage && <img src={lockedImage} />}
            <FontAwesomeIcon icon={faLock} className='locked-icon' />
          </>
        ) : (
          <Image
            imageUrl={getChannelLogo(logoUrl)}
            defaultUrl={getChannelLogoSmall(logoUrl) || '/img/noLogo.svg'}
          />
        )}
      </div>
      <span className='channel__title'>{`${channelNumber}. ${name}`}</span>
    </div>
  )
}
