import axios from 'axios'
import { domain } from '.'

// create child domain
const { createEffect } = domain('session')

// *
// * effects
// *

export const refreshSessionFx = createEffect({
  handler: () => axios.post('api/v3/session/refresh'),
})
