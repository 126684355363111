import React, { useCallback, useEffect, useState } from 'react'
import { navigate, Link } from '@reach/router'
import { connect, ConnectedProps } from 'react-redux'
import * as tvShowsActionCreators from '../tvShowsActions'
import { getTvShowsCategory, getTvShowsSubCategory } from '../store/selectors'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import { Button } from '~/components/common/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlay,
  faVideo,
  faHeart as faHeartSolid,
} from '@fortawesome/pro-solid-svg-icons'
import { TvShowInfo } from '../details-page-components/tvShow-info'
import { Season } from '../details-page-components/season'
import { Episode } from '../details-page-components/episode'
import { NoContent } from '~/components/common/no-content'
import { faFilm, faHeart } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { TvShow, TvShowEpisode, TvShowSeason } from '../interfaces'
import Image from '../../common/Image'
import { TooltipProvider } from '../details-page-components/tooltip/context'
import { Popup } from '~/components/common/popups'
import { Player } from '~/components/common/player/Player'
import { ContentType } from '~/components/home/constants'

interface TvShowDetailsPageProps extends ConnectedProps<typeof connector> {
  tvShowId: number
  location: { [key: string]: string }
}

function TvShowDetails(props: TvShowDetailsPageProps) {
  const {
    tvShowId,
    activeTvShow,
    activeCategory,
    activeSubCategory,
    getTvShowById,
    getSeasons,
    getEpisodesBySeason,
    getEpisodes,
    seasons,
    episodes,
    activeSeason,
    activeEpisode,
    setActiveSeason,
    setActiveEpisode,
    location,
    updateHistory,
    resetHistory,
    history,
  } = props

  const [isModal, setIsModal] = useState(false)

  const [tvShow, setTvShow] = useState<TvShow | Record<string, never>>({})
  const [tvShowSeasons, setTvShowSeasons] = useState<TvShowSeason[] | []>([])
  const [tvShowEpisodes, setTvShowEpisodes] = useState<TvShowEpisode[] | []>([])

  const { t } = useTranslation()

  const setTvShowAsActive = async (tvShowId) => {
    const response = await getTvShowById(tvShowId)
    const show = response.payload
    setTvShow(show)
  }
  const setActiveTvShowSeasons = async () => {
    const seasons = await getSeasons(tvShow.id).then(
      (response) => response?.payload
    )
    setTvShowSeasons(seasons)

    setActiveSeason(
      seasons.find((s) => s.id === activeSeason?.id) || seasons[0] || {}
    )
  }

  const setActiveTvShowEpisodes = async () => {
    let episodes
    tvShow?.seasonCount && activeSeason
      ? (episodes = await getEpisodesBySeason({
          tvShowId: tvShow.id,
          seasonId: activeSeason.id,
        }).then((response) => response?.payload))
      : (episodes = await getEpisodes(tvShow.id).then(
          (response) => response?.payload
        ))
    setTvShowEpisodes(episodes)
    setActiveEpisode(
      episodes.find((e) => e.id === history[activeSeason.id]) || {}
    )
  }

  const closeModal = () => {
    document.fullscreenElement ? document.exitFullscreen() : setIsModal(false)
  }
  useEffect(() => {
    Number(tvShowId) !== activeTvShow.id && resetHistory()
    setTvShowAsActive(tvShowId)
  }, [tvShowId])

  useEffect(() => {
    tvShow?.id && setActiveTvShowSeasons()
  }, [tvShow])

  useEffect(() => {
    tvShow?.id && setActiveTvShowEpisodes()
  }, [activeSeason])

  const navigateToPlayerPage = (seasonId, episodeId) => {
    return navigate(`${location.pathname}/${seasonId}/${episodeId}`)
  }

  const handleSeason = async (
    e: React.MouseEvent<HTMLDivElement>,
    season: TvShowSeason
  ) => {
    setActiveSeason(season)
    const episodes = await getEpisodesBySeason({
      tvShowId,
      seasonId: season.id,
    }).then((response) => response.payload)
    e.preventDefault()
    setTvShowEpisodes(episodes)
    setActiveEpisode(episodes.find((e) => e.id === history[season.id]) || {})
  }

  const handleEpisode = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    episode: TvShowEpisode
  ) => {
    updateHistory({ [activeSeason.id]: episode.id })
    setActiveEpisode(episode)
    return navigateToPlayerPage(activeSeason.id || 0, episode.id || 0)
  }

  const handlePlay = useCallback(() => {
    if (seasons.length) {
      const season = seasons[0]
      setActiveSeason(season)
      return getEpisodesBySeason({
        tvShowId,
        seasonId: season.id,
      }).then((episodes) => {
        const episode = episodes.payload[0]
        setActiveEpisode(episode || {})
        updateHistory({ [season.id]: episode.id })
        return navigateToPlayerPage(season.id || 0, episode.id)
      })
    }
    if (episodes && episodes.length) {
      const episode = episodes[0]
      setActiveEpisode(episode)
      return navigateToPlayerPage(0, episode.id)
    }
    return false
  }, [seasons, episodes])

  return (
    <TooltipProvider>
      <div className='tvShow-page'>
        <div className='breadcrumbs'>
          <Link to={`/shows`}>{t('TV Series')}</Link>
          {activeCategory && (
            <Link to={`/shows/categories/${activeCategory.id}`}>
              {activeCategory.name === 'All' ? t('All') : activeCategory.name}
            </Link>
          )}
          {activeSubCategory && (
            <Link
              to={`/shows/${
                activeCategory ? `categories/${activeCategory.id}/` : ''
              }subs/${activeSubCategory.id}`}
            >
              {activeSubCategory.name}
            </Link>
          )}
          <span>{tvShow.name}</span>
        </div>
        <div className='tvShow-page__content'>
          <div className='tvShow tvShow-details'>
            <h2 className='tvShow-title'>{tvShow.name}</h2>
            <div className='scrolable'>
              <div className='tvShow-poster'>
                {tvShow?.landscapeImageUrl ? (
                  <Image
                    imageUrl={tvShow.landscapeImageUrl}
                    defaultUrl={'/img/noContentIcon.svg'}
                  />
                ) : (
                  <div className='no-content-image'>
                    <img src={'/img/noContentIcon.svg'} alt='' />
                  </div>
                )}
              </div>
              {!tvShow.episodeCount ? null : (
                <Button
                  title={
                    <>
                      <FontAwesomeIcon icon={faPlay} />
                      <span>{t('tvshow:Play from the beginning')}</span>
                    </>
                  }
                  handleClick={handlePlay}
                  className={'button-base__flex button-primary'}
                />
              )}

              <div className='tvShow-buttons'>
                {activeTvShow.trailerUrl && (
                  <Button
                    title={
                      <>
                        <FontAwesomeIcon icon={faVideo} />
                        <span>{t('Trailer')}</span>
                      </>
                    }
                    className='button-chips button-ghost'
                    handleClick={() => setIsModal(true)}
                  />
                )}
                {window.ENV.APP_ADD_TO_FAVORITE_ENABLED && (
                  <Button
                    title={
                      <>
                        <FontAwesomeIcon
                          icon={tvShow.favorite ? faHeartSolid : faHeart}
                        />
                        <span>{t('Favorite')}</span>
                      </>
                    }
                    className='button-chips button-ghost'
                    handleClick={() => true}
                  />
                )}
              </div>
              <TvShowInfo tvShow={tvShow as TvShow} />
            </div>
          </div>

          {tvShow.seasonCount ? (
            <div className='tvShow-seasons'>
              <h2 className='tvShow-seasons__title block-title'>
                {t('tvshow:Seasons')}
              </h2>
              <div className='tvShow-seasons__list'>
                {tvShowSeasons.map((season) => (
                  <Season
                    key={season.id}
                    season={season}
                    isActive={season.id === activeSeason?.id}
                    handleClick={handleSeason}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {tvShow.episodeCount ? (
            <div className='tvShow-episodes'>
              <h2 className='tvShow-episodes__title block-title'>
                {t('tvshow:Episodes')}
              </h2>
              <div className='tvShow-episodes__list'>
                {tvShowEpisodes.length ? (
                  tvShowEpisodes.map((episode) => (
                    <Episode
                      key={episode.id}
                      episode={episode}
                      isActive={episode.id === activeEpisode?.id}
                      handleClick={handleEpisode}
                    />
                  ))
                ) : (
                  <p className='no-content-info'>
                    {t('tvshow:There are no Episodes yet')}
                  </p>
                )}
              </div>
            </div>
          ) : tvShow.seasonCount ? (
            <div className='tvShow-episodes'>
              <h2 className='tvShow-episodes__title block-title'>
                {t('tvshow:Episodes')}
              </h2>
              <p className='no-content-info'>
                {t('tvshow:There are no Episodes yet')}
              </p>
            </div>
          ) : !tvShow.seasonCount && !tvShow.episodeCount ? (
            <NoContent
              icon={<FontAwesomeIcon icon={faFilm} />}
              title={t('tvshow:There is no content yet')}
            />
          ) : null}
        </div>
        {tvShow.trailerUrl && isModal ? (
          <Popup className={'trailer-popup'}>
            <Player
              src={tvShow.trailerUrl!}
              skin='vod'
              contentType={ContentType.TV_SHOW}
            >
              <Button
                title={<FontAwesomeIcon icon={faTimes} />}
                className={'close-player__button'}
                handleClick={closeModal}
              />
            </Player>
          </Popup>
        ) : null}
      </div>
    </TooltipProvider>
  )
}

function mapStateToProps(state, props) {
  return {
    activeTvShow: state.views.tvShows.activeTvShow,
    activeSeason: state.views.tvShows.activeSeason,
    activeEpisode: state.views.tvShows.activeEpisode,
    seasons: state.views.tvShows.seasons,
    episodes: state.views.tvShows.episodes,
    url: state.views.tvShows.url,
    activeCategory: getTvShowsCategory(state, props),
    activeSubCategory: getTvShowsSubCategory(state, props),
    categories: state.views.tvShows.categories,
    history: state.views.tvShows.history,
  }
}

const mapDispatchToProps = {
  getTvShowById: tvShowsActionCreators.getTvShowByIdRequest,
  getSeasons: tvShowsActionCreators.getSeasonsRequest,
  getEpisodesBySeason: tvShowsActionCreators.getEpisodesBySeasonRequest,
  getEpisodes: tvShowsActionCreators.getEpisodesRequest,
  setActiveTvShow: tvShowsActionCreators.setActiveTvShow,
  setActiveEpisode: tvShowsActionCreators.setActiveEpisode,
  setActiveSeason: tvShowsActionCreators.setActiveSeason,
  getEpisodeUrl: tvShowsActionCreators.getEpisodeUrlRequest,
  getEpisodeUrlBySeason: tvShowsActionCreators.getEpisodeUrlBySeasonRequest,
  resetHistory: tvShowsActionCreators.resetHistory,
  updateHistory: tvShowsActionCreators.updateHistory,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const TvShowDetailsPage = connector(TvShowDetails)
