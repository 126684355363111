import { createSlice, original } from '@reduxjs/toolkit'
import * as vodActions from '/components/vod/store/actions'
import { assignAllCategories } from '/utils/helpers'

const initialState = {
  activeMovie: null,
  activeCategory: 0,
  categories: {
    content: [],
    page: 0,
    totalPages: 0,
  },
  content: {
    search: [],
  },
  subContent: {
    search: [],
  },
}

const vodSlice = createSlice({
  name: 'vod',
  initialState,
  reducers: {
    setActiveMovie: (state, { payload }) => {
      state.activeMovie = payload
    },
    setActiveMovieUrl: (state, { payload }) => {
      if (state.activeMovie) {
        state.activeMovie = Object.assign(state.activeMovie, { url: payload })
      }
    },
    setSearchContent: (state, { payload }) => {
      state.content.search = payload
    },
    setCategoriesPageState: (state, { payload }) => {
      const { page } = payload
      state.categories.page = page
    },
  },
  extraReducers: {
    [vodActions.getVODUrl.fulfilled.toString()]: (state, { payload }) => {
      const { drm, playbackUrl, stoppedTime } = payload
      const activeMovie = original(state.activeMovie)
      if (activeMovie) {
        state.activeMovie = Object.assign(state.activeMovie, {
          url: playbackUrl,
          drm,
          stoppedTime,
        })
      }
    },
    [vodActions.getVODUrl.rejected.toString()]: (state) => {
      const activeMovie = original(state.activeMovie)
      if (activeMovie) {
        state.activeMovie = Object.assign(state.activeMovie, {
          url: null,
          drm: null,
          stoppedTime: null,
        })
      }
    },

    [vodActions.getVodsByCategory.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const { content, last, number, totalElements, totalPages } =
        payload.data.payload
      state.content[payload.categoryId] = {
        movies: content,
        props: {
          last,
          curNumber: number,
          totalElements,
          totalPages,
        },
      }
    },

    [vodActions.updateVodsByCategory.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const content = state.content[payload.categoryId]
      if (content) {
        content.movies = content.movies.concat(payload.data.content)
        content.props = {
          last: payload.data.last,
          curNumber: payload.data.number,
          totalElements: payload.data.totalElements,
          totalPages: payload.data.totalPages,
        }
      }
    },

    [vodActions.getVodsBySubCategory.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      state.subContent[payload.categoryId] = {
        movies: payload.content,
        props: {
          last: payload.last,
          curNumber: payload.number,
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        },
      }
    },

    [vodActions.updateVodsBySubCategory.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const content = state.subContent[payload.categoryId]
      if (content) {
        content.movies = content.movies.concat(payload.content)
        content.props = {
          last: payload.last,
          curNumber: payload.number,
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        }
      }
    },

    [vodActions.getCategories.fulfilled.toString()]: (state, { payload }) => {
      state.categories.content = state.categories.content.length
        ? state.categories.content.concat(payload.content)
        : assignAllCategories(0, { data: payload.content })
      state.categories.totalPages = payload.totalPages
      state.categories.page = payload.number
    },

    [vodActions.getVodsBySearch.fulfilled.toString()]: (state, { payload }) => {
      state.content.search = payload.content
    },
  },
})

export const actions = {
  ...vodSlice.actions,
  ...vodActions,
}

export const reducer = vodSlice.reducer
