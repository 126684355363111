import axios from 'axios'
import { isServerUnableProcess, repeatRequest, api } from '.'

export const fetchCatchups = () => {
  return axios.get(`${api}/tv/catchups`).catch((error) => {
    const { status } = error.response
    if (isServerUnableProcess(status)) {
      repeatRequest(() => fetchCatchups())
    } else {
      throw error
    }
  })
}

export const fetchCatchupByChannel = (channelId) => {
  return axios.get(`${api}/tv/${channelId}/catchups`).catch((error) => {
    const { status } = error.response
    if (isServerUnableProcess(status)) {
      repeatRequest(() => fetchCatchupByChannel(channelId))
    } else {
      throw error
    }
  })
}
