import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchVODByCategory } from '~/services/api/vod'
import {
  fetchCategories,
  fetchSubCategories,
  fetchVods,
  fetchVodsByIds,
  fetchVODUrl,
  getVodsTrailerUrl,
} from '~/services/api/v3/vods'
import { actions as vodActions } from '~/components/vod/store'
import { showPreloader, hidePreloaderImmediately } from '/models/preloader'

export const getVODById = createAsyncThunk(
  'vod/getVODRequest',
  async (vodId: number, { rejectWithValue, dispatch }) => {
    try {
      const { payload } = await fetchVodsByIds(String(vodId))

      if (payload.length) {
        const vod = payload[0] as any // HACK!
        const trailerUrl = vod?.trailerPresent
          ? await getVodsTrailerUrl(vodId).then(
              (response) => response?.payload?.playbackUrl
            )
          : ''

        dispatch(vodActions.setActiveMovie({ ...vod, trailerUrl: trailerUrl }))
        dispatch(vodActions.getVODUrl(vodId))
        return { ...vod }
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getVODUrl = createAsyncThunk(
  'vod/getVODUrlRequest',
  async (vodId: number, { rejectWithValue }) => {
    try {
      const result = await fetchVODUrl(vodId)
      return result.payload
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getVodsByCategory = createAsyncThunk(
  'vod/getVodCategoryRequest',
  async (
    {
      categoryId,
      page,
      count,
    }: { categoryId: number; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      showPreloader()
      const result = await fetchVods(page, count, categoryId)
      hidePreloaderImmediately()
      return { categoryId, data: result }
    } catch (e) {
      hidePreloaderImmediately()
      return rejectWithValue(e)
    }
  }
)

export const updateVodsByCategory = createAsyncThunk(
  'vod/updateVodCategoryRequest',
  async (
    {
      categoryId,
      page,
      count,
    }: { categoryId: number; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      showPreloader()
      const result = await fetchVODByCategory(categoryId, page, count)
      hidePreloaderImmediately()
      return { categoryId, data: result }
    } catch (e) {
      hidePreloaderImmediately()
      return rejectWithValue(e)
    }
  }
)

export const getVodsBySubCategory = createAsyncThunk(
  'vod/getVodSubCategoryRequest',
  async (
    {
      categoryId,
      page,
      count,
    }: { categoryId: number; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchVODByCategory(categoryId, page, count)

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const updateVodsBySubCategory = createAsyncThunk(
  'vod/updateVodSubCategoryRequest',
  async (
    {
      categoryId,
      page,
      count,
    }: { categoryId: number; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchVODByCategory(categoryId, page, count)

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getVodsBySearch = createAsyncThunk(
  'vod/getVodsBySearchRequest',
  async (
    { q, page = 0, count = 999 }: { q: string; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchVods(page, count, 0, null, q)
      return q ? result.payload : [] // if submit empty field
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

/* actions with v3 */
/* - */
export const getCategories = createAsyncThunk(
  'vod/getCategoriesRequest',
  async (
    { page, count = 10 }: { page: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchCategories(page, count)
      return result.payload
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

/* get sub categories by category id */
export const getSubCategories = createAsyncThunk(
  'vod/getSubCategoriesRequest',
  async (
    {
      categoryId,
      page,
      count = 10,
    }: { categoryId: number; page: number; count: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchSubCategories(categoryId, page, count)

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const updateSubCategories = createAsyncThunk(
  'vod/updateSubCategoriesRequest',
  async (
    {
      categoryId,
      page,
      count = 10,
    }: { categoryId: number; page: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchSubCategories(categoryId, page, count)

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
/* - */
/* END */
