import React from 'react'
import { connect } from 'react-redux'
import { useStore } from 'effector-react'
import { useNavigate } from '@reach/router'
import { SliderCarousel } from '/components/common/carousel/Slider'
import * as tvShowsActionCreators from '/components/tvShows/tvShowsActions'
import { ResourceType, ContentType } from '../constants'
import { setURLParams } from '/utils/helpers'
import { actions as vodActions } from '/components/vod/store'
import { actions as tvActions } from '/components/tv/store'
import { $isCarouselsEnabled } from '/models/carousels'
import { getBanner } from '/components/home/store/selectors'
import { path as showPath } from '/components/tvShows/routes'
import { path as vodPath } from '/components/vod/routes'
import { path as tvPath } from '/components/tv/routes'
import { categoriesPath } from '~/root/routes'

const BannerCarousel = ({
  banner,
  page,
  tvShowsCategories,
  selectChannel,
  selectCategory,
  selectMovie,
  vodContent,
  loadCategory,
  loadCachedCategory,
  setActiveTvShow,
  getSeasons,
  setActiveSeason,
  getEpisodesBySeason,
  getEpisodes,
  setActiveEpisode,
  getCashedTvShowsByCategory,
  getTvShowsByCategory,
}) => {
  const isCarouselsEnabled = useStore($isCarouselsEnabled)
  const navigate = useNavigate()

  const selectVodCategory = (category) => {
    const { id } = category

    const isCashed = Boolean(vodContent[id])
    if (isCashed) {
      loadCachedCategory(id)
    } else {
      loadCategory(category)
    }
  }

  const handleTVShowsCategory = (category) => {
    const { id } = category
    const isCashed = Boolean(tvShowsCategories[id])
    if (isCashed) {
      getCashedTvShowsByCategory(id)
    } else {
      getTvShowsByCategory(category)
    }
  }

  const handleClick = async (item, index) => {
    setURLParams(location, 'banner', index)

    if (item.resourceType === ResourceType.INTERNAL) {
      const { contentType, content } = item

      switch (contentType) {
        case ContentType.TV_CHANNEL:
          selectChannel(content)
          navigate(`/${tvPath}/${content.id}`, {
            state: { from: page },
          })
          break

        case ContentType.TV_CATEGORY:
          const tvCategory = content
          // selectChannel({})
          selectCategory(tvCategory.id)
          navigate(`/${tvPath}/${categoriesPath}/${content.id}`, {
            state: { from: page },
          })
          break

        case ContentType.VOD:
          const movie = content
          selectMovie(movie)
          navigate(`/${vodPath}/${movie.id}`, {
            state: { from: page },
          })
          break

        case ContentType.VOD_CATEGORY:
        case ContentType.VOD_SUBCATEGORY:
          selectVodCategory(content)
          navigate(`/${vodPath}/${categoriesPath}/${content.id}`, {
            state: { from: page },
          })
          break

        case ContentType.TV_SHOW:
          const tvShow = content
          setActiveTvShow(tvShow)

          if (tvShow.isWithSeason) {
            const seasons = await getSeasons(tvShow.id)
            const activeSeason = seasons[0]
            if (activeSeason) {
              setActiveSeason(activeSeason)
              const episodes = await getEpisodesBySeason(
                tvShow.id,
                activeSeason.id
              )
              const activeEpisode = episodes[0]
              if (activeEpisode) setActiveEpisode(activeEpisode)
            }
          } else {
            const episodes = await getEpisodes(tvShow.id)
            const activeEpisode = episodes[0]
            if (activeEpisode) setActiveEpisode(activeEpisode)
          }
          if (page) {
            navigate(`/${showPath}/${content.id}`, {
              state: { from: page },
            })
          }
          break

        case ContentType.TV_SHOW_CATEGORY:
        case ContentType.TV_SHOW_SUBCATEGORY:
          handleTVShowsCategory(content)
          navigate(`/${showPath}/${categoriesPath}/${content.id}`, {
            state: { from: page },
          })
          break

        default:
          return
      }
    }
  }

  return isCarouselsEnabled && banner ? (
    <SliderCarousel carousel={banner} handleClick={handleClick} />
  ) : null
}

function mapStateToProps(state) {
  return {
    banner: getBanner(state),
    vodContent: state.views.vod.content,
    tvShowsCategories: state.views.tvShows.content,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectChannel: (channel) => dispatch(tvActions.changeChannel(channel)),
    selectCategory: (categoryId) =>
      dispatch(tvActions.setActiveCategory(categoryId)),
    selectMovie: (movie) => dispatch(vodActions.setActiveMovie(movie)),
    loadCachedCategory: (categoryId) =>
      dispatch(vodActions.getVodsByCategory({ categoryId, page: 0 })),
    loadCategory: (category) =>
      dispatch(
        vodActions.getVodsByCategory({ categoryId: category.id, page: 0 })
      ),
    setActiveTvShow: (tvShow) =>
      dispatch(tvShowsActionCreators.setActiveTvShow(tvShow)),
    getSeasons: (tvShowId) =>
      dispatch(tvShowsActionCreators.getSeasonsRequest(tvShowId)),
    setActiveSeason: (season) =>
      dispatch(tvShowsActionCreators.setActiveSeason(season)),
    getEpisodesBySeason: (tvShowId, seasonId) =>
      dispatch(
        tvShowsActionCreators.getEpisodesBySeasonRequest({
          tvShowId,
          seasonId,
        })
      ),
    getEpisodes: (tvShowId) =>
      dispatch(tvShowsActionCreators.getEpisodesRequest(tvShowId)),
    setActiveEpisode: (episode) =>
      dispatch(tvShowsActionCreators.setActiveEpisode(episode)),
    getCashedTvShowsByCategory: (categoryId) =>
      dispatch(tvShowsActionCreators.getActiveCategoryRequest(categoryId)),
    getTvShowsByCategory: (category) =>
      dispatch(
        tvShowsActionCreators.getTvShowsByCategory({
          id: category.id,
          page: 0,
          count: 36,
        })
      ),
  }
}

export const BannerCarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerCarousel)
