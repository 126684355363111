import { Link } from '@reach/router'
import React from 'react'
import { formatDateForEPG } from '~/utils/date'

import './styles.scss'

interface IProgram {
  id: number
  channelId: number
  name: string
  videoUrl: string
  startTimestamp: number
  startDate: string
  endDate: string
  part: number
}

interface ProgramProps {
  program: IProgram
  handleClick: (program) => void
}

export const Program = ({
  program,
  handleClick,
}: ProgramProps): JSX.Element => {
  return (
    <Link
      to={`/catchup/${program.channelId}/${program.id}`}
      className={`program `}
      onClick={handleClick}
    >
      <span className='program__time'>{`${formatDateForEPG(
        program.startDate
      )} - ${formatDateForEPG(program.endDate)}`}</span>
      <span className='program__title'>{program.name}</span>
    </Link>
  )
}
