import { Route } from '~/root/routes'
import { CatchupHomePage } from '../pages/home'
import { CatchupPlayerPage } from '../pages/player-page'

export const path = 'catchup'

export const catchupRoutes = (catchupEnabled: boolean): Route[] => [
  {
    path: `/${path}`,
    name: 'Catchups',
    component: CatchupHomePage,
    enable: catchupEnabled,
    main: true,
    state: { shouldStateReset: true },
  },
  {
    path: `/${path}/:channelId/:programId`,
    name: 'Catchups',
    component: CatchupPlayerPage,
    enable: catchupEnabled,
  },
]
