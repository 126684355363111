import React from 'react'
import { useNavigate } from '@reach/router'
import { connect } from 'react-redux'
import * as tvShowsActionCreators from '/components/tvShows/tvShowsActions'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../../common/carousel/Carousel'
import { HorizontalCard } from '../../common/cards/horizontal-card/HorizontalCard'
import { FeaturedCard } from '../../common/cards/featured-card/FeaturedCard'
import { CardTypes } from '/utils/helpers'

const TvShowsCarousel = ({
  carousel,
  page,
  setActiveTvShow,
  getSeasons,
  setActiveSeason,
  getEpisodesBySeason,
  getEpisodes,
  setActiveEpisode,
  cardType,
  id,
}) => {
  const navigate = useNavigate()
  const maxLength = 36
  const { t } = useTranslation('home')

  const handleTvShow = async (tvShow, index) => {
    setActiveTvShow(tvShow)

    if (tvShow.isWithSeason) {
      const seasons = await getSeasons(tvShow.id)
      const activeSeason = seasons.payload[0]
      if (activeSeason) {
        setActiveSeason(activeSeason)
        const episodes = await getEpisodesBySeason(tvShow.id, activeSeason.id)
        const activeEpisode = episodes.payload[0]
        if (activeEpisode) setActiveEpisode(activeEpisode)
      }
    } else {
      const episodes = await getEpisodes(tvShow.id)
      const activeEpisode = episodes.payload[0]
      if (activeEpisode) setActiveEpisode(activeEpisode)
    }
    navigate(`/shows/categories/0/${tvShow.id}`, { state: { page } })
  }

  return carousel?.content?.length ? (
    <Carousel
      id={id}
      carouselTitle={t('Featured TV Series')}
      showAllOption={false}
      className='featured'
      categoryId={'featuredShow'}
    >
      {carousel.content
        .slice(0, maxLength)
        .map((item, index) =>
          cardType === CardTypes.featuredCard ? (
            <FeaturedCard
              key={`${item.tvShow.name}${item.tvShow.id}`}
              path={'/shows'}
              handleClick={() => handleTvShow(item.tvShow, index)}
              imageUrl={item.tvShow.landscapeImageUrl || item.tvShow.imageUrl}
              title={item.tvShow.name}
              ageRatings={item.tvShow.ageRatings}
              releaseDate={item.tvShow.year}
            />
          ) : (
            <HorizontalCard
              key={`${item.tvShow.name}${item.tvShow.id}`}
              path={``}
              handleClick={() => handleTvShow(item.tvShow, index)}
              imageUrl={item.tvShow.landscapeImageUrl || item.tvShow.imageUrl}
              title={item.tvShow.name}
            />
          )
        )}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    carousel: state.views.tv.carousels?.find((item) => item.type === 'TV_SHOW'),
    activeTvShow: state.views.tvShows.activeTvShow,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveTvShow: (tvShow) =>
      dispatch(tvShowsActionCreators.setActiveTvShow(tvShow)),
    getSeasons: (tvShowId) =>
      dispatch(tvShowsActionCreators.getSeasonsRequest(tvShowId)),
    setActiveSeason: (season) =>
      dispatch(tvShowsActionCreators.setActiveSeason(season)),
    getEpisodesBySeason: (tvShowId, seasonId) =>
      dispatch(
        tvShowsActionCreators.getEpisodesBySeasonRequest({
          tvShowId,
          seasonId,
        })
      ),
    getEpisodes: (tvShowId) =>
      dispatch(tvShowsActionCreators.getEpisodesRequest(tvShowId)),
    setActiveEpisode: (episode) =>
      dispatch(tvShowsActionCreators.setActiveEpisode(episode)),
  }
}

export const TvShowsCarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TvShowsCarousel)
