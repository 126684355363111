import React from 'react'
import { useStore } from 'effector-react'
import LoginBox from './LoginBox'
import { doLogin, $loginError } from '/models/login'

export const Login = () => {
  const loginError = useStore($loginError)
  return (
    <div className='login'>
      <LoginBox doLogin={doLogin} loginError={loginError} />
    </div>
  )
}
