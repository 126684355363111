import axios from 'axios'
import { isServerUnableProcess, repeatRequest } from '../'
import { vods as api } from './'
import { Movie } from '/components/vod/interfaces'

/**
 * Find vods by ids
 * @param ids
 */
export const fetchVodsByIds = (
  ids: string | number[]
): Promise<{ payload: [Movie] }> => {
  return axios
    .get(`${api}?ids=${ids}`)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchVodsByIds(ids))
      } else {
        return error
      }
    })
}

/**
 * Find vod by id
 * @param id
 */
export const fetchVodById = (id: number): Promise<{ payload: [Movie] }> => {
  return axios
    .get(`${api}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchVodById(id))
      } else {
        return error
      }
    })
}

/**
 * Find VODs by category id
 * @returns {Promise<T>}
 */
export const fetchVods = (
  page = 0,
  count = 100,
  categoryId?: number,
  ids?: number[] | null,
  q?: string
) => {
  let url = `${api}?page=${page}&count=${count}`

  if (q) url += `&q=${q}`
  if (ids) url += `&ids=${ids}`
  if (categoryId) url += `&categoryId=${categoryId}`

  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchVods(categoryId, page, count))
      } else {
        throw error
      }
    })
}

/**
 * Fetch VOD url by id
 * @param {Number} id
 * @returns {Promise<T>}
 */
export const fetchVODUrl = (id: number) => {
  return axios
    .get(`${api}/${id}/url`)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchVODUrl(id))
      } else {
        throw error
      }
    })
}

/**
 * get VOD's trailer Url
 * @param {Number} moovieId
 * @returns {Promise<T>}
 */
export const getVodsTrailerUrl = (moovieId: number) =>
  axios
    .get(`${api}/${moovieId}/trailer/url`)
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Set continue watching vod
 * @param id
 * @param stoppedTime
 */
export const putContinueWatchingVod = (
  id: number,
  stoppedTime: number
): Promise<unknown> => {
  return axios
    .put(`${api}/${id}/continue-watching`, { stoppedTime })
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => putContinueWatchingVod(id, stoppedTime))
      } else {
        return error
      }
    })
}

/**
 * Get continue watching vods
 */
export const getContinueWatching = (): Promise<number[]> => {
  // TODO: moved to index
  return axios
    .get('/api/v3/continue-watching')
    .then((response) => {
      return response.data.payload.content
        .filter((item) => item.contentType === 'VOD')
        .map((v) => v.contentId)
    })
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => getContinueWatching())
      } else {
        return error
      }
    })
}

/**
 * Mark vod as watched
 * @param id
 */
export const markVodAsWatched = (id: number): Promise<unknown> => {
  return axios.patch(`${api}/${id}`, { watched: true }).catch((error) => {
    const { status } = error.response
    if (isServerUnableProcess(status)) {
      return repeatRequest(() => markVodAsWatched(id))
    } else {
      return error
    }
  })
}

/**
 * Get vod categories list with pagination. [V3]
 * @param page
 * @param count
 */
export const fetchCategories = (page: number, count: number) => {
  return axios
    .get(`${api}/categories?page=${page}&count=${count}`)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchCategories(page, count))
      } else {
        throw error
      }
    })
}

/**
 * Get vod subcategories list with pagination by category id. [V3]
 * @param categoryId
 * @param page
 * @param count
 */
export const fetchSubCategories = (
  categoryId: number,
  page: number,
  count: number
) => {
  return axios
    .get(
      `${api}/categories/${categoryId}/subcategories?page=${page}&count=${count}`
    )
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchSubCategories(categoryId, page, count))
      } else {
        throw error
      }
    })
}
