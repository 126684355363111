import React from 'react'
import { useTranslation } from 'react-i18next'
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '~/components/common/button'
import { logoutFx } from '~/models/login'
import s from '../../pages/settings.module.scss'
import { useModal } from '../modals'
import { DialogModal } from '../modals/dialog-modal'

export const Actions = () => {
  const { setModal } = useModal()
  const { t } = useTranslation('settings')

  const handleLogoutClick = () =>
    setModal(
      <DialogModal
        title={t('Log out')}
        message={t('Do you really want to log out?')}
        confirmButtonTitle={'Log out'}
        onConfirm={logoutFx}
      />
    )
  return (
    <div className={s.actions}>
      <h3 className={s.title}>{t('Actions')}</h3>
      <div className={s.actionsWrapper}>
        <Button
          handleClick={() => handleLogoutClick()}
          className={'button-base button-ghost'}
          title={
            <>
              <FontAwesomeIcon icon={faPowerOff} />
              <span>{t('common:button.Log out')}</span>
            </>
          }
        />
      </div>
    </div>
  )
}
