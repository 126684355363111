import { createReducer } from '@reduxjs/toolkit'
import {
  getRecentTvShowsRequest,
  getTvShowByIdRequest,
  changeTvShowsSearchQuery,
  getTvShowsBySearchRequest,
  setActiveTvShow,
  getSeasonsRequest,
  setSeasons,
  setActiveSeason,
  getEpisodesRequest,
  getEpisodesBySeasonRequest,
  setEpisodes,
  setActiveEpisode,
  getEpisodeUrlBySeasonRequest,
  getEpisodeUrlRequest,
  getCategoriesRequest,
  getActiveCategoryRequest,
  getTvShowsByCategory,
  getTvShowsByCategoryByPage,
  getEpisodeUrlSuccess,
  changeTvShowsSearch,
  updateUrl,
  resetHistory,
  updateHistory,
} from './tvShowsActions'
import { sortBySortOrder, assignAllCategories } from '/utils/helpers'

// initial state of tv shows
export const initialState = {
  recentTvShows: [],
  page: NaN,
  lastPage: false,
  searchQuery: '',
  categories: [],
  activeCategory: 0,
  content: {
    search: [],
  },
  activeTvShow: {},
  seasons: [],
  activeSeason: {},
  episodes: [],
  activeEpisode: {},
  url: '',
  history: {},
}

export const tvShowsReducer = createReducer(initialState, {
  [getTvShowByIdRequest.fulfilled]: (state, { payload }) => {
    state.activeTvShow = payload
  },
  [getTvShowByIdRequest.rejected]: (state) => {
    state.activeTvShow = initialState.activeTvShow
  },
  [getRecentTvShowsRequest.fulfilled]: (state, { payload }) => {
    state.recentTvShows = payload.content
  },
  [getRecentTvShowsRequest.rejected]: (state) => {
    state.recentTvShows = initialState.recentTvShows
  },

  [getTvShowsBySearchRequest.fulfilled]: (state, { payload }) => {
    state.content.search = payload
  },
  [getTvShowsBySearchRequest.rejected]: (state) => {
    state.content.search = []
  },
  [changeTvShowsSearchQuery]: (state, { payload }) => {
    state.searchQuery = payload
  },
  [changeTvShowsSearch]: (state, { payload }) => {
    state.content.search = payload
  },

  [setActiveTvShow]: (state, { payload }) => {
    state.activeTvShow = payload
  },

  [getSeasonsRequest.fulfilled]: (state, { payload }) => {
    state.seasons = payload
  },
  [getSeasonsRequest.rejected]: (state) => {
    state.seasons = []
  },
  [setSeasons]: (state, { payload }) => {
    state.seasons = payload
  },
  [setActiveSeason]: (state, { payload }) => {
    state.activeSeason = payload
  },

  [getEpisodesRequest.fulfilled]: (state, { payload }) => {
    state.episodes = sortBySortOrder(payload)
  },
  [getEpisodesRequest.rejected]: (state) => {},
  [getEpisodesBySeasonRequest.fulfilled]: (state, { payload }) => {
    state.episodes = sortBySortOrder(payload)
  },
  [getEpisodesBySeasonRequest.rejected]: (state) => {},

  [setEpisodes]: (state, { payload }) => {
    state.episodes = payload
  },
  [setActiveEpisode]: (state, { payload }) => {
    state.activeEpisode = payload
  },
  [getEpisodeUrlBySeasonRequest.fulfilled]: (state, { payload }) => {
    state.url = payload.playbackUrl
  },
  [getEpisodeUrlBySeasonRequest.rejected]: (state) => {
    state.url = ''
  },
  [getEpisodeUrlRequest.fulfilled]: (state, { payload }) => {
    state.url = payload.playbackUrl
  },
  [getEpisodeUrlRequest.rejected]: (state) => {
    state.url = ''
  },
  [getEpisodeUrlSuccess]: (state, { payload }) => {
    state.url = payload
  },

  [getCategoriesRequest.fulfilled]: (state, { payload }) => {
    state.categories = assignAllCategories(0, { data: payload })
  },
  [getCategoriesRequest.rejected]: (state) => {
    state.categories = []
  },
  [getActiveCategoryRequest]: (state, { payload }) => {
    state.activeCategory = payload.activeCategory
    state.content.search = []
  },
  [getTvShowsByCategory.fulfilled]: (state, { payload }) => {
    state.activeCategory = payload.activeCategory
    state.content = {
      ...state.content,
      [payload.activeCategory]: {
        movies: payload.content.content,
        page: {
          number: payload.content.number,
          last: payload.content.last,
          totalElements: payload.content.totalElements,
        },
      },
    }
  },
  [getTvShowsByCategory.rejected]: (state) => {
    state.activeCategory = 0
    state.content = initialState.content
  },
  [getTvShowsByCategoryByPage.fulfilled]: (state, { payload }) => {
    state.activeCategory = payload.activeCategory
    state.content[payload.activeCategory].movies = state.content[
      payload.activeCategory
    ].movies.concat(payload.content.content)
    state.content[payload.activeCategory].page.number = payload.content.number
    state.content[payload.activeCategory].page.last = payload.content.last
    state.content[payload.activeCategory].page.totalElements =
      payload.content.totalElements
  },
  [getTvShowsByCategoryByPage.rejected]: (state) => {
    state.activeCategory = 0
    state.content = initialState.content
  },
  [updateUrl]: (state, { payload }) => {
    state.url = payload
  },
  [updateHistory]: (state, { payload }) => {
    state.history = { ...state.history, ...payload }
  },
  [resetHistory]: (state) => {
    state.history = {}
  },
})
