import React, { useCallback, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from '@reach/router'
import * as tvShowActionCreators from '/components/tvShows/tvShowsActions'
import { getTvShowsContentById } from '/components/tvShows/store/selectors'
import { useTranslation } from 'react-i18next'
import { TvShow, TvShowCategory } from '../interfaces'
import { HorizontalCard } from '/components/common/cards/horizontal-card/HorizontalCard'
import { Grid } from '/components/common/grid-cards-layout'
import { Carousel } from '/components/common/carousel/Carousel'
import SearchField from '/components/common/SearchField'
import { NoContent } from '/components/common/no-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { isIncludesIdenticalSubstrings, findCategoryById } from '/utils/helpers'

export const TvShowsCategory = (props: any) => {
  const {
    id,
    categoryId,
    subCategoryId,
    content,
    currentContent,
    categories,
    location,
    search,
    loadContent,
    getTvShowsByCategoryByPage,
    searchQuery,
    changeTvShowsSearchQuery,
    getTvShowsBySearchRequest,
  } = props
  const maxLength = 36
  const navigate = useNavigate()

  const [category, setCategory] = useState<any>(null)
  const [subCategory, setSubCategory] = useState<any>(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (typeof id === 'number' && !currentContent)
      return loadContent({ id, page: 0, count: maxLength })

    const subCategories = categories?.find((c) => c.id === id)?.subCategories
    if (id && subCategories && subCategories.length)
      subCategories.map(({ id }) =>
        loadContent({ id, page: 0, count: maxLength })
      )

    // HARDCODE!!
    const currentCategory = findCategoryById(categories, Number(categoryId))
    currentCategory && setCategory(currentCategory)
    if (currentCategory?.subCategories) {
      const sub = currentCategory?.subCategories?.find(
        (c) => c.id === Number(subCategoryId)
      )
      setSubCategory(sub)
    }
  }, [id, categories, currentContent])

  const handleCategory = useCallback((id: number) => {
    return navigate(`${location.pathname}/subs/${id}`)
  }, [])

  const handleTvSHow = useCallback(
    (showId: number, category?: TvShowCategory) => {
      let url = `${location.pathname}/${showId}`

      if (category && category.subCategories && !category.subCategories.length)
        url = `${location.pathname}/subs/${category.id}/${showId}`

      return navigate(url)
    },
    []
  )

  const getItemsByPage = (id, page) => {
    getTvShowsByCategoryByPage({ id, page, count: maxLength })
  }

  const handleSearch = (searchQuery) => {
    if (searchQuery)
      getTvShowsBySearchRequest({
        searchQuery,
        categoryId: 0,
      })
    return changeTvShowsSearchQuery(searchQuery)
  }

  const subs = categories?.find((c) => c.id === id)?.subCategories

  return (
    <div className='content nomargin'>
      <div className='page-search-breadcrumbs-wrapper'>
        <div className='breadcrumbs'>
          <Link to={`/shows`}>{t('TV Series')}</Link>
          {category && (
            <Link to={`/shows/categories/${category?.id}`}>
              {category.name === 'All' ? t('All') : category.name}
            </Link>
          )}
          {subCategory && (
            <Link
              to={`/shows/categories/${category?.id}/subs/${subCategory.id}`}
            >
              {subCategory.name}
            </Link>
          )}
        </div>
        <SearchField
          placeholder={t('tvshow:Search TV Series')}
          handleSearch={handleSearch}
        />
      </div>
      {searchQuery ? (
        search && search.length ? (
          <Grid
            id={0}
            page={{ number: 0, last: true }}
            getItemsByPage={() => null}
            title={t('Search Result ({{length}})', {
              length: search.length,
            })}
            className={'base-cards'}
          >
            {search.map((item: TvShow) => (
              <HorizontalCard
                key={`${item.id}${item.name}`}
                title={item.name}
                imageUrl={item.landscapeImageUrl || ''}
                handleClick={() => handleTvSHow(item.id)}
              />
            ))}
          </Grid>
        ) : (
          <NoContent
            icon={<FontAwesomeIcon icon={faSearch} className='icon' />}
            title={t('No search results found')}
          />
        )
      ) : subs &&
        subs.length &&
        !isIncludesIdenticalSubstrings(location.pathname, `${id}`) ? (
        subs.concat(categories?.find((c) => c.id === id) || []).map((item) => {
          return (
            <Carousel
              id={`show_${item.id}`}
              key={item.id}
              carouselTitle={`${item.name}`}
              showAllOption={content[item.id]?.page?.totalElements > maxLength}
              handleCategoryClick={() => handleCategory(item.id)}
            >
              {content?.[item.id]?.movies?.map((show: TvShow) => {
                return (
                  <HorizontalCard
                    key={`${show.id}${show.name}`}
                    title={show.name}
                    imageUrl={show.landscapeImageUrl || ''}
                    handleClick={() => handleTvSHow(show.id, item)}
                  />
                )
              })}
            </Carousel>
          )
        })
      ) : (
        <Grid
          id={id}
          contextSelector={`show_${id}`}
          page={currentContent?.page}
          getItemsByPage={getItemsByPage}
          title={
            category?.name === 'All'
              ? t('All')
              : subCategory?.name || category?.name
          }
          className={'base-cards'}
        >
          {currentContent?.movies?.map((item: TvShow) => (
            <HorizontalCard
              key={`${item.id}${item.name}`}
              title={item.name}
              imageUrl={item.landscapeImageUrl || ''}
              handleClick={() => handleTvSHow(item.id)}
            />
          ))}
        </Grid>
      )}
    </div>
  )
}

function mapStateToProps(state, props) {
  return {
    id: +props.subCategoryId || +props.categoryId || 0,
    categories: state.views.tvShows.categories,
    content: state.views.tvShows.content,
    search: state.views.tvShows.content.search,
    activeCategory: state.views.tvShows.activeCategory,
    searchQuery: state.views.tvShows.searchQuery,
    currentContent: getTvShowsContentById(state, props),
  }
}

const mapDispatchToProps = {
  loadContent: tvShowActionCreators.getTvShowsByCategory,
  getActiveCategoryRequest: tvShowActionCreators.getActiveCategoryRequest,
  getTvShowsByCategoryByPage: tvShowActionCreators.getTvShowsByCategoryByPage,
  getTvShowsBySearchRequest: tvShowActionCreators.getTvShowsBySearchRequest,
  changeTvShowsSearchQuery: tvShowActionCreators.changeTvShowsSearchQuery,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const TvShowsCategoryPage = connector(TvShowsCategory)
