import axios from 'axios'
import { tv, tvShows as api } from '.'
import { isServerUnableProcess, repeatRequest } from '../'

/**
 * Find recent tv shows list
 * @return {Promise<{ payload }>}
 */
export const findRecentTvShows = () =>
  axios
    .get(`${api}?count=10&sort-by=addedTime&sort-order=desc`)
    .then((response) => response.data)

/**
 * Find page by search
 * @param {String} searchQuery
 * @param {Number} categoryId
 * @param {Number} page
 * @return {Promise<{ payload }>}
 */
const findPageBySearch = ({ searchQuery, categoryId = 0, page = 0 }) =>
  axios
    .get(
      `${api}?q=${encodeURIComponent(
        searchQuery
      )}&page=${page}&categoryId=${categoryId}`
    )
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Find tv shows recursion
 * @param {String} searchQuery
 * @param {Number} categoryId
 * @param {Number} page
 * @return {[]}
 */
export const findTvShowsBySearch = async ({
  searchQuery,
  categoryId = 0,
  page = 0,
}) => {
  const { payload } = await findPageBySearch({ searchQuery, categoryId, page })
  const { totalPages, content } = payload
  const promises = await Promise.all(
    Array.from({ length: totalPages - 1 }, (_, i) =>
      findPageBySearch({ searchQuery, categoryId, page: i + 1 })
    )
  )
  return promises.reduce(
    (result, { payload }) => result.concat(payload.content),
    content
  )
}

/**
 * Find seasons by page
 * @param tvShowId
 * @param page
 * @return {Promise<{payload}>}
 */
const findSeasonsPage = (tvShowId, page = 0) =>
  axios
    .get(`${api}/${tvShowId}/seasons?page=${page}`)
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Find seasons recursion
 * @param tvShowId
 * @param page
 * @return {[]}
 */
export const findSeasons = async (tvShowId, page = 0) => {
  const { payload } = await findSeasonsPage(tvShowId, page)
  const { totalPages, content } = payload
  const promises = await Promise.all(
    Array.from({ length: totalPages - 1 }, (_, i) =>
      findSeasonsPage(tvShowId, i + 1)
    )
  )
  return promises.reduce(
    (result, { payload }) => result.concat(payload.content),
    content
  )
}

/**
 * Find episodes by show and seasons
 * @param tvShowId
 * @param seasonId
 * @param page
 * @return {Promise<{content>}
 */
const findEpisodesBySeasonByPage = (tvShowId, seasonId, page = 0) =>
  axios
    .get(`${api}/${tvShowId}/seasons/${seasonId}/episodes?page=${page}`)
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Find episodes by show and seasons recursion
 * @param tvShowId
 * @param seasonId
 * @param page
 * @return {[]}
 */
export const findEpisodesBySeason = async (tvShowId, seasonId, page = 0) => {
  const { payload } = await findEpisodesBySeasonByPage(tvShowId, seasonId, page)
  const { totalPages, content } = payload
  const promises = await Promise.all(
    Array.from({ length: totalPages - 1 }, (_, i) =>
      findEpisodesBySeasonByPage(tvShowId, seasonId, i + 1)
    )
  )
  return promises.reduce(
    (result, { payload }) => result.concat(payload.content),
    content
  )
}

/**
 * Find episodes by show
 * @param tvShowId
 * @param page
 * @return {Promise<{content>}
 */
const findEpisodesByPage = (tvShowId, page = 0) =>
  axios
    .get(`${api}/${tvShowId}/episodes?page=${page}`)
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Find episodes by show recursion
 * @param tvShowId
 * @param page
 * @return {[]}
 */
export const findEpisodes = async (tvShowId, page = 0) => {
  const { payload } = await findEpisodesByPage(tvShowId, page)
  const { totalPages, content } = payload
  const promises = await Promise.all(
    Array.from({ length: totalPages - 1 }, (_, i) =>
      findEpisodesByPage(tvShowId, i + 1)
    )
  )
  return promises.reduce(
    (result, { payload }) => result.concat(payload.content),
    content
  )
}

/**
 * Find episode url
 * @param tvShowId
 * @param seasonId
 * @param episodeId
 * @return {Promise<T>}
 */
export const findEpisodeUrlBySeason = async (tvShowId, seasonId, episodeId) =>
  axios
    .get(`${api}/${tvShowId}/seasons/${seasonId}/episodes/${episodeId}/url`)
    .then((response) => response.data)
    .catch((error) => error)
/**
 *  * Find episode url
 * @param tvShowId
 * @param episodeId
 * @return {Promise<T>}
 */
export const findEpisodeUrl = async (tvShowId, episodeId) =>
  axios
    .get(`${api}/${tvShowId}/episodes/${episodeId}/url`)
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Find categories by page
 * @param page
 * @return {Promise<T>}
 */
const findCategoriesPage = (page = 0) =>
  axios.get(`${api}/categories?page=${page}`).then((response) => response.data)

/**
 * Find all categories
 * @return {Promise<T>}
 */
export const findCategoriesPageByPage = async () => {
  const { payload } = await findCategoriesPage()
  const { totalPages, content } = payload
  const promises = await Promise.all(
    Array.from({ length: totalPages - 1 }, (_, i) => findCategoriesPage(i + 1))
  )
  return promises.reduce(
    (result, { payload }) => result.concat(payload.content),
    content
  )
}

/**
 * Find tv show by category id
 * @param categoryId
 * @param page
 * @param count
 * @return {Promise<T>}
 */
export const findTvShowsByCategoryByPage = async ({ id, page, count }) =>
  axios
    .get(`${api}/?categoryId=${id}&page=${page}&count=${count}`)
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Find tv show by sub category
 * @param categoryId
 * @param subCategoriesIds
 */
export const findTvShowsBySubcategories = (
  categoryId = 0,
  subCategoriesIds = []
) => {
  const allIds = subCategoriesIds.concat(categoryId)

  return Promise.all(allIds.map(findTvShowsByCategory)).then((values) => {
    if (values) {
      let result = []
      values.map(({ payload }) => payload.content.map((v) => result.push(v)))
      const formattedValues = result.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      )
      return {
        categoryId,
        formattedValues,
      }
    }
  })
}

/**
 * Find subcategories by category id
 * @param categoryId
 * @param page
 * @param count
 * @return {Promise<T>}
 */

export const findSubCategoriesByCategory = (
  categoryId = 0,
  page = 0,
  count = 25
) =>
  axios
    .get(
      `${api}/categories/${categoryId}/subcategories?page=${page}&count=${count}`
    )
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Find tvShow by id
 * @param tvShowId
 * @return {Promise<T>}
 */

export const findTvShowById = (tvShowId = 0) =>
  axios
    .get(`${api}?ids=${tvShowId}`)
    .then((response) => response.data)
    .catch((error) => error)

/**
 * Set continue watching episode without season
 * @param tvShowId
 * @param episodeId
 * @param stoppedTime
 * @returns {Promise<T>}
 */
export const putContinueWatchingEpisode = (
  episodeId,
  tvShowId,
  stoppedTime
) => {
  return axios
    .put(`${api}/${tvShowId}/episodes/${episodeId}/continue-watching`, {
      stoppedTime,
    })
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() =>
          putContinueWatchingEpisode(episodeId, tvShowId, stoppedTime)
        )
      } else {
        return error
      }
    })
}

/**
 * Set continue watching episode with season
 * @param tvShowId
 * @param seasonId
 * @param episodeId
 * @param stoppedTime
 * @returns {Promise<T>}
 */
export const putContinueWatchingEpisodeBySeason = (
  episodeId,
  tvShowId,
  stoppedTime,
  seasonId
) => {
  return axios
    .put(
      `${api}/${tvShowId}/seasons/${seasonId}/episodes/${episodeId}/continue-watching`,
      { stoppedTime }
    )
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() =>
          putContinueWatchingEpisodeBySeason(
            episodeId,
            tvShowId,
            stoppedTime,
            seasonId
          )
        )
      } else {
        return error
      }
    })
}

/**
 * @param tvShowId
 * @returns {Promise<T>}
 */
export const findTvShowsTrailerUrl = (tvShowId = 0) =>
  axios
    .get(`${api}/${tvShowId}/trailer/url`)
    .then((response) => response.data)
    .catch((error) => error)
