import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../common/carousel/Carousel'
import { FeaturedCard } from '../common/cards/featured-card/FeaturedCard'
import { getHorizontalVODLogo, getVODLogo } from './helpers'
import { HorizontalCard } from '../common/cards/horizontal-card/HorizontalCard'
import { CardTypes } from '../../utils/helpers'
import { useNavigate } from '@reach/router'
import { _ } from '/config'

const VodsCarousel = ({
  carousel,
  activePosition,
  id,
  handleMovieClick = null,
  cardType,
}) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation('home')

  const handleClick = async (id) => {
    if (typeof handleMovieClick === 'function') {
      handleMovieClick(id)
    }

    await navigate(`/vods/categories/0/${id}`)
  }

  return carousel?.content?.length ? (
    <Carousel
      id={id}
      activePosition={activePosition}
      carouselTitle={t(_('header-featured-movies'))}
      showAllOption={false}
      categoryId={'featuredVod'}
      className='featured'
    >
      {carousel.content
        .slice(0, maxLength)
        .map((item) =>
          cardType === CardTypes.featuredCard ? (
            <FeaturedCard
              key={`${item.name}${item.id}`}
              handleClick={() => handleClick(item.vod.id)}
              imageUrl={
                getHorizontalVODLogo(item.vod.imageHorizontalUrl) ||
                getVODLogo(item.vod.imageUrl)
              }
              title={item.vod.name}
              ageRatings={item.vod.ageRatings}
              releaseDate={item.vod.year}
            />
          ) : (
            <HorizontalCard
              key={`${item.name}${item.id}`}
              path={``}
              handleClick={() => handleClick(item.vod.id)}
              imageUrl={
                getHorizontalVODLogo(item.vod.imageHorizontalUrl) ||
                getVODLogo(item.vod.imageUrl)
              }
              title={item.vod.name}
            />
          )
        )}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    carousel: state.views.tv.carousels?.find((item) => item.type === 'VOD'),
  }
}

export const VodsCarouselContainer = connect(mapStateToProps)(VodsCarousel)
