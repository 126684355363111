import { createSlice, original } from '@reduxjs/toolkit'
import * as tvActions from '/components/tv/store/actions'
import {
  changeChannel,
  changeChannelsCategory,
  getCarousels,
  getChannelEPG,
  getChannelsCategories,
  rewindTvChannel,
  getRecentlyWatched,
} from '/components/tv/store/actions'

const initialState: any = {
  search: '',

  activeChannel: {},
  activeProgram: null,
  EPG: null,
  activeCategory: 0,

  categories: [],
  channels: [],
  content: {},
  carousels: [],
  recentlyWatched: [],
}

const tvSlice = createSlice({
  name: 'tv',
  initialState,
  reducers: {
    setActiveCategory: (state, { payload }) => {
      state.activeCategory = payload
    },
    setActiveChannel: (state, { payload }) => {
      state.activeChannel = {
        ...payload,
        fromSec: null,
        continueUrl: null,
      }
    },
    setEPG: (state, { payload }) => {
      state.EPG = payload || []
    },
    setActiveProgram: (state, { payload }) => {
      state.activeProgram = payload
    },
    setSearch: (state, { payload }) => {
      state.search = payload.q
    },
    changeMediaProgressBar: (state, { payload }) => {
      state.activeProgram = payload
    },
    setActiveChannelUrl: (state, { payload }) => {
      state.activeChannel = {
        ...payload,
        channelUrl: null,
      }
    },
    unlockChannel: (state) => {
      state.activeChannel.locked = false
    },
  },
  extraReducers: {
    [getChannelsCategories.fulfilled.toString()]: (state, { payload }) => {
      state.categories = payload
    },
    [getChannelsCategories.rejected.toString()]: (state) => {
      state.categories = initialState.categories
    },

    [getCarousels.fulfilled.toString()]: (state, { payload }) => {
      state.carousels = payload
    },

    [getCarousels.rejected.toString()]: (state) => {
      state.carousels = initialState.carousels
    },

    [changeChannelsCategory.fulfilled.toString()]: (state, { payload }) => {
      state.channels = payload

      state.content[0] = []
      state.categories.forEach((item) => {
        state.content[item.id] = []
      })

      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]

        state.content[0].push(item)

        for (let j = 0; j < item.categoryIds.length; j++) {
          const id = item.categoryIds[j]
          if (state.content[id]) {
            state.content[id].push(item)
          }
        }
      }
    },

    [changeChannel.fulfilled.toString()]: (state, { payload }) => {
      const { playbackUrl, drm } = payload
      const channel = original(state.activeChannel)
      state.activeChannel = {
        ...channel,
        drm,
        channelUrl: playbackUrl,
      }
    },
    [changeChannel.rejected.toString()]: (state) => {
      const channel = original(state.activeChannel)
      state.activeChannel = { ...channel, drm: null, channelUrl: null }
    },

    [getChannelEPG.fulfilled.toString()]: (state, { payload }) => {
      state.EPG = payload
    },
    [getChannelEPG.rejected.toString()]: (state) => {
      state.EPG = []
    },
    [rewindTvChannel.fulfilled.toString()]: (state, { payload }) => {
      const channel = original(state.activeChannel)
      const fromSec = payload?.fromSec
      state.activeChannel = {
        ...channel,
        channelUrl: payload?.playUrl,
        fromSec: fromSec ? fromSec * 1000 : null,
        continueUrl: payload?.continueUrl,
      }
    },
    [rewindTvChannel.rejected.toString()]: (state) => {
      const channel = original(state.activeChannel)
      state.activeChannel = {
        ...channel,
        channelUrl: null,
        fromSec: null,
        continueUrl: null,
      }
    },
    [getRecentlyWatched.fulfilled.toString()]: (state, { payload }) => {
      state.recentlyWatched = payload
    },
    [getRecentlyWatched.rejected.toString()]: (state) => {
      state.recentlyWatched = initialState.recentlyWatched
    },
  },
})

export const actions = {
  ...tvSlice.actions,
  ...tvActions,
}

export const reducer = tvSlice.reducer
